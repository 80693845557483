import React from "react";
import {Helmet} from "react-helmet";

const HelmetTag: React.FC <any> = ({
    title,
    metaDescription,
    extra_data,
    head_script
}: any) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta
                name="description"
                content={metaDescription}
            />
             <link rel="canonical" href={window.location.href} />
                {
                    extra_data ?
                        <script type="application/ld+json">
                            {`${JSON.stringify(head_script)}`}
                        </script>
                    : '' 
                }
        </Helmet>
    )
}

export default HelmetTag;
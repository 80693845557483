//import axios from "axios";
import { debounce } from "lodash";
// import { CsvDataService } from "./CsvDataService";
// import { CONSTANT } from "../config/Constant";

export const getFilterParams = (
  api: any,
  key: any,
  parameter?: any,
  thenAction?: any
) => {
  return {
    values: (params: any) => {
      api(parameter).then((data: any) => {
        thenAction && thenAction(data);
        return params.success([...data.map((x: any) => x[key])]);
      });
    },
    buttons: ["apply", "reset"],
    closeOnApply: true,
  };
};

export const setModelTypeSet = (params: any, column: string, values: any) => {
  const filterInstance = params.api.getFilterInstance(column);
  filterInstance.setModel({
    values: [values],
    filterType: "set",
  });
};
export const setModelTypeDate = (params: any, column: string, data: any) => {
  const filterInstance = params.api.getFilterInstance(column);
  filterInstance.setModel(data);
};

export const validateFields = debounce((form, setDisabled) => {
  form
    .validateFields()
    .then(() => {
      setDisabled(false);
    })
    .catch(() => {
      setDisabled(true);
    });
}, 500);

// export const exportCSVFile = async (
//   apiUrl: string,
//   data: any = { type: CONSTANT.EXPORT.TYPE.CSV }
// ) => {
//   return await axios.post(apiUrl, data).then(({ data }) => {
//     CsvDataService.exportToCsv(data.file_name, data.data, data.header);
//   });
// };

export const getRedirectedFilterForApi = (redirectedData: any) => {
  let redirectedFilter = null;
  if (redirectedData.zoneId || redirectedData.isOtherRecord) {
    redirectedFilter = {
      zone_id: {
        values: [redirectedData.zoneId],
        filterType: "set",
      },
    };
    if (redirectedData.wardId || redirectedData.isOtherRecord) {
      redirectedFilter = {
        ...redirectedFilter,
        ward_id: {
          values: [redirectedData.wardId],
          filterType: "set",
        },
      };
    }
    if (redirectedData.createdAtForApi) {
      redirectedFilter = {
        ...redirectedFilter,
        created_at: redirectedData.createdAtForApi,
      };
    }
  }
  return redirectedFilter;
};
export const renderCell = (data: any) => {
  return data ? data : "N/A";
};
// export const checkCities = (cityNames: any) => {
//   let isCity = false;
//   const cities = cityNames.split(",");
//   cities.forEach((city: any) => {
//     if (
//       CONSTANT.APP_MASTER.ID[city] === Number(localStorage.getItem("app_id"))
//     ) {
//       isCity = true;
//     }
//   });
//   return isCity;
// };

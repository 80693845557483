import React, {  } from 'react';
import { observer } from "mobx-react";
import ConnectAccount from '../../../components/Account/ConnectAccount';
//import ChainSelector from '../../../components/ChainSelector';


const HeaderMenuComponent: React.FC = observer(() => {
	return (
		<>
			<div className="col_etc">
				<div className="btn_wrap">
					{/* <ChainSelector /> */}
					<ConnectAccount />
				</div>
			</div>
		</>
	);
});

export default HeaderMenuComponent;

import { makeAutoObservable } from "mobx";
import AuthStore from "../AuthStore/AuthStore";
import resolveWeb3 from "../../lib/getWeb3";
import getContractInstance from "../../lib/getContract";
import productDefinition from "../../contracts/NFTProductContract.json";
import orderDefinition from "../../contracts/NFTOrderContract.json";
import auctionDefinition from "../../contracts/NFTAuctionContract.json";
import { CONSTANT } from "../../config/Constant";
import moment from "moment";

export default class NftItemStore {
	private authStore: AuthStore;
	public products?: null;
	public productCount?: 0;
	public product_details?: null;
	public product_block?: null;
	public product_event?: null;
	public productArray: any = [];
	public productObj: any = [];
	public availableMintCount?: 0;
	public blockInformation?: null;
	public ordersList?: null;
	public auctionsList?: null;
	public auctionBidsList?: null;
	public eventDetail?: null;
	public is_process?: true;
	public file?: null;

	constructor() {
		this.authStore = new AuthStore();
		makeAutoObservable(this);
	}

	public setProducts = (value: any): void => {
		this.products = value;
	};

	public setFile = (value: any): void => {
		this.file = value;
	};
	public setProductDetails = (value: any): void => {
		this.product_details = value;
	};

	public setProductBlock = (value: any): void => {
		this.product_block = value;
	};

	public setProductEvent = (value: any): void => {
		this.product_event = value;
	};

	public setAvailableMintCount = (value: any): void => {
		this.availableMintCount = value;
	};

	public setBlockInformation = (value: any): void => {
		this.blockInformation = value;
	};

	public setOrdersList = (value: any): void => {
		this.ordersList = value;
	};

	public setAuctionsList = (value: any): void => {
		this.auctionsList = value;
	};
	public setAuctionBidsList = (value: any): void => {
		this.auctionBidsList = value;
	};
	
	public setEventDetail = (value: any): void => {
		this.eventDetail = value;
	};

	public setProcess = (value: any): void => {
		this.is_process = value;
	};
	public setProductCount = (value: any): void => {
		this.productCount = value;
	};

	public loadProductHandler = async () => {
		const web3 = await resolveWeb3();
		this.setProcess(true);
		await getContractInstance(web3, productDefinition).then( async (StorageContract) => {
			await StorageContract.methods.getAllProducts().call().then((results: any) => {

				const listp = [];
				this.setProductCount(results.length);
				localStorage.setItem('product_count',results.length)
				for (let index = 0; index < results.length; index++) {
					const result = results[index];
					const tokenId = result["tokenId"];					
					if (CONSTANT.PRODUCT_EXCLUDE.indexOf(tokenId) === -1)
					{
						listp.push({
							tokenId: result["tokenId"],
							title: result['title'],
							image_url: result['imageURL'],
							quantity: result['quantity'],
							price: result['price'],
							description: result['description'],
							creator: result['creator'],
							category: result['category'],
						});
					}
				}
				listp.sort((a: any, b: any) => {
					return b.tokenId - a.tokenId;
				});
				this.setProducts(listp);
				this.setProcess(false);
				this.loadAuctionResponse(listp);
			});
			
		});
	
		
	};

	/**
	 *  Load Product Detail API
	 * @param active_account
	 * @param productId
	 */
	public loadProductDetailHandler = async (web3: any, productId: any) => {
		this.setProductDetails(null);
		await getContractInstance(web3, productDefinition).then( async(StorageContract:any) => {
			
			
		// Load Produc Details
		await StorageContract.methods
			.getProduct(productId)
			.call()
			.then((product: any) => {
				this.productObj = {
					ID: product.tokenId,
					title: product.title,
					image_url: product.imageURL,
					quantity: product.quantity,
					price: product.price,
					description: product.description,
					creator: product.creator,
					category: product.category,
				};
				this.setProductDetails(this.productObj);
			});				
		});
		
		await getContractInstance(web3, orderDefinition).then( async(StorageContract:any) => {

			this.setOrdersList(null);
			this.setEventDetail(null);

			// Load Order Data
			await StorageContract.methods
				.getProductWiseOrders(productId)
				.call()
				.then( async(odersResponse: any) => {
					const result: any = [];
					//convert array to object key and value
					odersResponse.allOrders.forEach((arr: any) => {
						const [orderId, tokenId, seller, buyer] = arr;
						if (arr['orderType'] === CONSTANT.ORDER_TRANSFER_TYPE) {
							result.push({
								key :orderId,
								orderId: orderId,
								tokenId: tokenId,
								purchasePrice: arr['purchasePrice'],
								seller: seller,
								buyer: buyer,
								orderType: arr['orderType'],
							});
						}
					});
					this.setOrdersList(result);
					this.setAvailableMintCount(odersResponse.minted_pending);
				});
				
				const blockNumber = await web3.eth.getBlockNumber();
				const blockInfo = await web3.eth.getBlock(blockNumber);
				//const fromBlock = Math.max(0, blockNumber - 800);
					
				this.setBlockInformation(blockInfo);


				// // Load Event Data
				await StorageContract.getPastEvents(
					"TransferProcessed",
					{
						filter: {tokenId: productId },
						fromBlock: '0x0',
						toBlock: "latest",
						address: StorageContract._address 
					},
					function (events: any) {
						return events;
					}
				).then(async (events: any) => {
					//console.log('events',events);
					// Object key for the Table
					const eventsData = events.filter((events:any) => events.returnValues
					.amount !== '0').map((item:any) => {
						return {key : item.id,...item}
					});					
					this.setEventDetail(eventsData);
				}).catch( (error:any) => {
					console.log('error',error);
					
				});

				await getContractInstance(web3, auctionDefinition).then( async(StorageContract:any) => {
					// Load Order Data
					
					await StorageContract.methods
						.getProductWiseAuctions(productId)
						.call()
						.then( async(auctionsResponse: any) => {
							const auctionResult: any = [];
							const auctionBidResult: any = [];
							
							//convert array to object key and value
							auctionsResponse.forEach( async (arr: any) => {
								const [id, orderId, tokenId] = arr;
								if(tokenId > 0){
									auctionResult.push({
										key :id,
										orderId: orderId,
										tokenId: tokenId,
										maxPrice: arr["maxPrice"],
										minPrice: arr["minPrice"],
										maxBid: arr["maxBid"],
										auctionType: arr["auctionType"],
										startTime: arr["startTime"],
										endTime: arr["endTime"],
										createdDate: arr["createdDate"],
										status: arr["status"],
										bidWinningPrice: arr["bidWinningPrice"],
										winner: arr["winner"],
									});

									// List of Bid By auctionId
									await StorageContract.methods
									.getAuctionBids(id)
									.call()
									.then( async(auctionBidsResponse: any) => { 
										if(auctionBidsResponse.length > 0) {
											auctionBidsResponse.forEach( async (arrBid: any) => {
												auctionBidResult.push({
													key :arrBid["id"],
													id :arrBid["id"],
													auctionId: arrBid["auctionId"],
													bidPrice: arrBid["bidPrice"],
													buyer: arrBid["buyer"],
													createdDate: arrBid["createdDate"]
												});
											});
										}
										this.setAuctionBidsList(auctionBidResult);
										
									});
								}
							});
							this.setAuctionsList(auctionResult);
						});
		
					});
		});
	};

	/**
	 * This method use for get auction list for home page 
	 * @param productLists 
	 * @returns 
	 */
	public loadAuctionResponse = async ( productLists: any) => {

		localStorage.removeItem(CONSTANT.AUCTION_QUICK_LIST);
		
		let loopEnd = 20;
		const web3 = await resolveWeb3();
		const currentDate = moment().unix();
		const auctionHomeResult: any = [];
		
		await getContractInstance(web3, auctionDefinition).then( async (StorageContract) => {
			for (let index = 1; index <= loopEnd; index++) {
				await StorageContract.methods.getAuctionByID(index.toString()).call().then((auctionResult: any) => {
					if (auctionResult["startTime"] > currentDate.toString() &&  auctionResult["endTime"] > currentDate.toString() )
					{
						const single_product = productLists.filter((plist:any) => plist.tokenId == auctionResult["tokenId"] )

						auctionHomeResult.push({
							key :auctionResult["id"],
							tokenId: auctionResult["tokenId"],
							price: single_product ? single_product[0].price : 0,
							img_url: single_product ? single_product[0].image_url : null,
							title: single_product ? single_product[0].title : null,
							maxPrice: auctionResult["maxPrice"],
							minPrice: auctionResult["minPrice"],
							startTime: auctionResult["startTime"],
							endTime: auctionResult["endTime"],
							endTimeFormated: moment.unix(parseInt(auctionResult["endTime"])).format("YYYY-MM-DD HH:mm:ss"),
							startTimeFormated: moment.unix(parseInt(auctionResult["startTime"])).format("YYYY-MM-DD HH:mm:ss")
						});
					}
					localStorage.setItem(CONSTANT.AUCTION_QUICK_LIST,JSON.stringify(auctionHomeResult.sort((a:any, b:any) => (a.startTime - b.startTime)).slice(0,4)));
				})
			}
		})
	}
}


import React from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Col, Image } from "antd";
import { CONSTANT } from '../../../config/Constant';
import { convertToSlug } from '../../../config/Global';

const RelatedItemSlick: React.FC<any> = ({products,detail_id}) => {
    const settings = {
        infinite: false,
        dots: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        speed: 800,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 539,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <>
            <Slider {...settings} className='slick slick_related'>
                {products.length > 0 &&  products.filter((products:any) => products.tokenId !== detail_id).map((filteredProduct:any, i:any) => (
                    <Col xs={{ span: 24 }} className='slick_item' key={i}>
                        <div className="col_wrap">
                            <div className="img_wrap">
                                {/* <img src={filteredProduct.image_url} alt="BlockChain" style={{height : "240px"}} /> */}
                                <Image src={filteredProduct.image_url}  preview={false} style={{height : "240px"}} />
                            </div>
                            <div className="content_wrap">
                                <h3>
                                    <Link to={`/product-details/${filteredProduct.tokenId+'/'+convertToSlug(filteredProduct.title)}`}>{filteredProduct.title}</Link>
                                </h3>
                                <p>{filteredProduct.description.substring(0,50)}</p>
                                <small>{filteredProduct.price} {CONSTANT.CURRENCY_CODE}</small>
                            </div>
                        </div>
                    </Col>
                ))}
            </Slider>
        </>
    )
}

export default RelatedItemSlick

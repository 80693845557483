import React, { useCallback, useState } from "react";

import { useWeb3React } from "@web3-react/core";
import { Dropdown, Space, MenuProps, Tooltip } from "antd";

import { metaMask } from "../../connectors/metaMask";
//import { walletConnect } from "../../connectors/walletConnect";
import { coinbaseWallet } from "../../connectors/coinbaseWallet";
import { getEllipsisTxt } from "../../utils/formatters";

import ConnectModal from "./ConnectModal";
import DisconnectModal from "./DisconnectModal";
import Jazzicons from "../Jazzicons";
import { Link } from "react-router-dom";
//import { ReactComponent as SunIcon } from "../../assets/images/icons/sun.svg";
import { ReactComponent as WalletConnect } from "../../assets/images/icons/WalletConnect.svg";
import { ReactComponent as UserIcon } from "../../assets/images/icons/userIcon.svg";
import { ReactComponent as CollectionIcon } from "../../assets/images/icons/collectionIcon.svg";
import { ReactComponent as CreateProductIcon } from "../../assets/images/icons/createProductIcon.svg";
import { ReactComponent as ModeIcon } from "../../assets/images/icons/modeIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/icons/logoutIcon.svg";
import { DownOutlined } from '@ant-design/icons';

interface WantedChain {
  chain?: number;
}

const ConnectAccount: React.FC<WantedChain> = () => {
  const { account,connector } = useWeb3React();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);

  const disconnect = useCallback(async () => {
        
    setIsModalVisible(false);
    setIsAuthModalOpen(false);
    localStorage.removeItem("connectorId");
    if (connector.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    // @ts-expect-error close can be returned by wallet
    if (connector && connector.close) {
      // @ts-expect-error close can be returned by wallet
      await connector.close();
    }
  }, []);

  //const itemsEmpty: MenuProps['items'] = [];
  const items: MenuProps['items'] = [
    {
      label: <Link to={'/my-collections'}>My Collection</Link>,
      key: '0',
      icon: <CollectionIcon />,
    },
    {
      label: <Link to={'/add-product'}>Create Product</Link>,
      key: '1',
      icon: <CreateProductIcon />,
    },
    {
      label: <Link to={'/'} onClick={disconnect}>Logout</Link>,
      key: '3',
      icon: <LogoutIcon />,
    },
  ];
  
  return (
    <>
      {account === undefined ? (
        <>
            <div className='linkDropdown'>
                <Link to="#0" onClick={() => setIsAuthModalOpen(true)} className="btn btn_primary">
                    <WalletConnect /> <b>Wallet Connect </b>
                </Link>
                <ConnectModal isModalOpen={isAuthModalOpen} setIsModalOpen={setIsAuthModalOpen} />
                <div className='UserDropDown'>
                    {/* <Link  to="#">
                        <UserIcon />
                    </Link> */}
                    <Tooltip title="Please Connect Wallet">
                    <Dropdown overlayClassName ="dropDownUser"  trigger={['click']} placement="bottomRight" disabled={true}>
                        <Link to={'#'} onClick={(e) => e.preventDefault()}>
                            <Space>
                                <UserIcon />
                                <DownOutlined />
                            </Space>
                        </Link>
                    </Dropdown>
                    </Tooltip>
                </div>
            </div>
        </>
      ) : (
        <>
          <div className='linkDropdown'>
					<Link to="#0" onClick={() => setIsModalVisible(true)} className="btn btn_primary">
						<WalletConnect /> 
                        {account && typeof account === "string" && ( <b style={{ marginRight: "5px" }}>{getEllipsisTxt(account, 6)}</b>  )}
                        <Jazzicons seed={account} />
					</Link>
                    
					<div className='UserDropDown'>
						<Dropdown overlayClassName ="dropDownUser" menu={{ items }} trigger={['click']} placement="bottomRight">
              <Link to={'#'} onClick={(e) => e.preventDefault()}>
								<Space>
									<UserIcon />
									<DownOutlined />
								</Space>
							</Link>
						</Dropdown>
					</div>
                    <DisconnectModal isModalOpen={isModalVisible} setIsModalOpen={setIsModalVisible} disconnect={disconnect} />
				</div>

        </>
      )}
    </>
  );
};

export default ConnectAccount;

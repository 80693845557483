import { notification } from "antd";
import { RcFile } from "antd/lib/upload";
import moment from "moment";
import { CONSTANT } from "./Constant";
import { Buffer } from "buffer";

export const displayFormats = {
  DATE_FORMAT: "MM-DD-YYYY",
  TIME_FORMAT: "HH:mm",
  DATE_TIME_FORMAT: "MM-DD-YYYY HH:mm",
};

export const uploadedFileOnPreview = async (file: any) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as RcFile);
      reader.onload = () => resolve(reader.result as string);
    });
  }

  const image = new Image();
  image.src = src;
  const imgWindow: any = window.open(src);
  imgWindow.document.write(image.outerHTML);
};

export const Notification = {
  success: (data: any) => {
    notification.success({
      placement: data.placement ? data.placement : "bottomRight",
      duration: 3,
      ...data,
    });
  },
  error: (data: any) => {
    notification.error({
      placement: data.placement ? data.placement : "bottomRight",
      duration: 3,
      ...data,
    });
  },
};

export const convertToSlug = (str: any) => {
  return str.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

export const snake_case_string = (str: any) => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((s: any) => s.toLowerCase())
      .join("_")
  );
};

export const camel_case_string = (str: any) => {
  str = str
    .replace(/[-_]+/g, " ")
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: any) {
      return index !== 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/ (.)/g, function ($1: any) {
      return $1.toUpperCase();
    });
  return str;
};

export const convertTextToID = (
  text_array: any,
  main_array: any,
  text_key: any,
  id_key: any
) => {
  const new_array: any = [];
  if (text_array && text_array.values && text_array.values.length > 0) {
    text_array.values.forEach((x: any) => {
      const temp = main_array.find((y: any) => y[text_key] === x);

      if (x && temp) {
        new_array.push(temp[id_key]);
      } else {
        insertAt(new_array, 0, x);
      }
    });
  }
  return new_array;
};

function insertAt(array: any, index: any, ...elementsArray: any) {
  array.splice(index, 0, ...elementsArray);
}

export const dataToFormdataConverter = (data: any) => {
  let formData = new FormData();
  for (let name in data) {
    const value = data[name];
    if (value !== "" && value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        name = `${name}[]`;
        value.map((item:any) => {
          formData.append(name, item);
        });
      } else {
        formData.append(name, value);
      }
    }
    // formData.append(name, data[name]); // there should be values.avatar which is a File object
  }
  return formData;
};

export const dateFormatter = (
  date: any,
  format = CONSTANT.DATE_TIME_FORMAT
) => {
  return moment.unix(date).format(format);
};

export const b64toBlob = (b64Data: any, contentType: any, sliceSize?: any) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  const byteCharacters = Buffer.from(b64Data, "base64").toString("binary");
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  //console.log(byteArrays);

  return new File(byteArrays, "pot", { type: contentType });
};

export const exportPDF = (data: any) => {
  if (data && data.pdf) {
    const file = b64toBlob(data.pdf, "application/pdf");
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    window.open(fileURL);
  }
};


export const addressFormatter = (address: any) => {
	if (!address) return
	const startDigit = address.slice(0, 5);
	const endDigit = address.slice(35, 50);
	return startDigit + ' ... ' + endDigit;
};

export const categoryNameById = (id:any) => { 
  const active_cat:any =  CONSTANT.NFT_CATEGORY_ARRAY.filter( (category:any) =>  category.key == id); 
  return active_cat ? active_cat[0]?.label : "";
}


export const descendingFormate = (arrayData:any) => {
  return  [...arrayData].sort((a, b) => b.id - a.id);
}

export const ascendingFormate = (arrayData:any) => {
  return  [...arrayData].sort((a, b) =>
    a.id > b.id ? 1 : -1,
  );
}
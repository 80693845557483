import React, { useState } from 'react'
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import Details from './Tabs/Details';
import Assets from './Tabs/Assets';
import Transactions from './Tabs/Transactions';
import Auctions from './Tabs/Auctions';
import { CONSTANT } from '../../../../config/Constant';

const ProductTabs: React.FC<any> = ({
  data,
  availableForMint,
  orderItem,
  auctionItem,
  auctionBids,
  eventDetail,
  blockInformation
}) => {
  const getActiveTab = localStorage.getItem(CONSTANT.LOCALSTORAGE_TOKEN.ACTIVE_TAB) ? localStorage.getItem(CONSTANT.LOCALSTORAGE_TOKEN.ACTIVE_TAB) : CONSTANT.LOCALSTORAGE_TOKEN.DEFAULT_PRODUCT_DETAIL_TAB;
    
  const [activeTab,setActiveTab] = useState<any>(getActiveTab)
  const onChange = (key:any) => {
    setActiveTab(key);
    localStorage.setItem(CONSTANT.LOCALSTORAGE_TOKEN.ACTIVE_TAB,key);
  };
  
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Listing`,
      children: <Transactions data={data} availableForMint={availableForMint} orderItem={orderItem} />,
    },
    {
      key: '2',
      label: `Detail`,
      children: <Details data={data} availableForMint={availableForMint} blockInformation={blockInformation}  />,
    },
    {
      key: '3',
      label: `Activities`,
      children: <Assets data={data} availableForMint={availableForMint} orderItem={eventDetail} />,
    },
    {
      key: '4',
      label: `Auctions`,
      children: <Auctions data={data} auctionItem={auctionItem} bidLists={auctionBids} />,
    }
  ];
  return (  
    <div>
      <Tabs defaultActiveKey={activeTab} items={items} onChange={onChange} className='tab__wrap' />
    </div>
  )
}

export default ProductTabs;

import { makeAutoObservable } from "mobx";
import RootStore from "../RootStore/RootStore";
import getWeb3 from "../../lib/getWeb3";
import getContractInstance from '../../lib/getContract'
//import contractDefinition from "../../contract-alc/NFTMain.json";
import contractDefinition from "../../contracts/NFTProductContract.json";

export default class AuthStore {
	// Variables
	public app_loading: boolean;
	private rootStore: RootStore;
	public web3?: null;
	public contract?: null;

	constructor() {
		this.app_loading = true;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Initiate Application Functions
	public InitializeApp = (): void => {
		this.rootStore.setAxiosBaseUrl();
		this.rootStore.setAxiosInterceptor();
		this.rootStore.setNotificationConfig();
		this.initWeb3Container();
	};

	public initWeb3Container = async () => {
		try {
			const web3 = await getWeb3();
			this.setWeb3(web3);
			// get contract instance
			const contract = await getContractInstance(web3, contractDefinition);
			this.setContract(contract);
		} catch (error) {
			console.error("Could not connect to contract or chain.");
		}
		this.setAppLoading(false);
	};

	// Setter Functions

	public setAppLoading = (value: boolean): void => {
		this.app_loading = value;
	};

	public setWeb3 = (value: any): void => {
		this.web3 = value;
	};

	
	public setContract = (value: any): void => {
		this.contract = value;
	};
	
}

import React,{ useEffect} from "react";
import { observer } from "mobx-react";
import { Col, Row, Skeleton } from 'antd';
import useStore from "../../../store";
import ProductInfo from "./Component/ProductInfo";
import RelatedItemSlick from "../Product/RelatedItemSlick";
import ProductTabs from "./Component/ProductTabs";
import { useParams } from "react-router-dom";
import Seo from "../../../components/Seo";

const ProductDetails: React.FC<any> = observer(() => {
    const { productId } = useParams();
   
    const { AUTH, NFT_MARKETPLACE_STORE } = useStore();
	const {
		web3
	} = AUTH;

    const { 
		products,
        product_details,
        loadProductHandler,
        loadProductDetailHandler,
        availableMintCount,
        ordersList,
        auctionsList,
        auctionBidsList,
        eventDetail,
        blockInformation,

	} = NFT_MARKETPLACE_STORE;

    useEffect(() => {
        loadProductHandler();
		loadProductDetailHandler(web3,productId);
	},[productId,web3,loadProductHandler,loadProductDetailHandler]);  
        
    return (
        <>
                
                <section className='product_section pv-100'>
                    <div className="container_sm">
                        {
                            product_details ?
                            <>
                            <Seo extra_data={product_details} />
                                <Row className="pr_details">
                                    
                                        <Col md={12} lg={10} className="col_img">
                                            <img src={product_details.image_url} alt="" />
                                        </Col>

                                        <Col md={24} lg={13} className="col_content">
                                            <ProductInfo 
                                                data={product_details} 
                                                availableForMint={availableMintCount} 
                                            />
                                        </Col>
                                </Row>

                                <Row className="">
                                    <Col lg={24} className="col_tabs">
                                        <ProductTabs 
                                        data={product_details} 
                                        availableForMint={availableMintCount} 
                                        orderItem={ordersList}
                                        auctionItem={auctionsList}
                                        auctionBids={auctionBidsList}
                                        eventDetail={eventDetail ? eventDetail : []}
                                        blockInformation={blockInformation ? blockInformation : []}
                                        />
                                    </Col>
                                </Row>
                                {
                                    products && products.length > 1 ?   (
                                        <div className="products_list retaled_slider">
                                            <h3>Related Item</h3>
                                            <RelatedItemSlick products={products} detail_id={productId} />
                                        </div>
                                    ) : (<></>)
                                }
                            </>
                            : 
                            <Row className="pr_details" style={{ minHeight: "50vh" }}>   
                                <Col md={12} lg={10} className="col_img">
                                    <Skeleton.Image style={{ width: "100%", height: '100%' }} active className="test" />
                                </Col>
                                <Col md={24} lg={13} className="col_content" style={{ display: "flex",justifyContent: "center", flexDirection: "column" }}>
                                    <Skeleton active />
                                    <Skeleton active />
                                    <Skeleton active />
                                </Col>
                            </Row>
                        }
                    </div>
                </section>
        </>
    );
});

export default ProductDetails;

import React, { useEffect } from "react";
import { Modal,Form, message } from "antd";
import { useWriteContract } from "../../../../hooks";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import CancelButton from "../../../../components/Buttons/CancelButton";
import SaveButton from "../../../../components/Buttons/SaveButton";
import { validateFields } from "../../../../utils/ValidationFunctions";

const AuctionBid: React.FC<any>= ({
  open,
  setOpen, 
  auctionId,
  auctionMinPrice,
  auctionMaxPrice, 
  ...rest 
}) => {
    const [form] = Form.useForm();
    const {placeAuctionBid, loading,modelBox} = useWriteContract()
    const [disabled, setDisabled] = React.useState<boolean>(loading);
    const [saving, setSaving] = React.useState<boolean>(loading);
    
    useEffect(() => {
      setDisabled(loading)
      setSaving(loading)
      
    },[loading,modelBox])

    const onSubmit = async(data: any) => {
      setSaving(true);
      const price = data.bid_price ? data.bid_price : 0
      try{
        await placeAuctionBid(price,auctionId).then((response: any) => {
          setOpen(false);
        });
      } catch(error: any) {
        const err = error.reason ?? error.message ?? error;
        message.error(`An error occured: ${err}`);
        setSaving(false)
        setDisabled(false)
      }    
    };

    const onValuesChange = () => {
      validateFields(form, setDisabled);
    };

    const modalClose = () => {
      setOpen(false);
    };   
      
    return(
      <Modal
      title={""}
      width={300}
      centered
      onCancel={modalClose}
      destroyOnClose
      open={open}
      className="auctionModel"
      footer={[
        <CancelButton key={2} onClick={modalClose} />,
        <SaveButton
          key={1}
          form="bid_form"
          name="Place Bid"
          disabled={disabled}
          loading={saving}
        />,
      ]}
      closeIcon={
        <span className="btn btn_primary btn_modalClose" title="Close">
          <span className="iconFont-close"></span>
        </span>
      }
      {...rest}
    >
     
      <FormBox
        form={form}
        id="bid_form"
        onFinish={onSubmit}
        onValuesChange={onValuesChange}
        initialValues={{
          'bid_price': auctionMinPrice
        }}
      >
        <InputBox.Number
          name="bid_price"
          label="Bid Price"
          min={auctionMinPrice} 
          max={auctionMaxPrice}
        />
      </FormBox>
    </Modal>
    )
}

export default AuctionBid;
import React, { useEffect, useState } from "react";
import { Button, Table, Space,Badge, Tooltip } from "antd";
import { dateFormatter } from "../../../../../config/Global";
import { CONSTANT } from "../../../../../config/Constant";
import { EyeOutlined } from '@ant-design/icons';
import AuctionBid from "../AuctionBid";
import AuctionBidList from "../../AuctionBidList";
//import { useWriteContract } from "../../../../../hooks";


const Auctions: React.FC<any> = ({ auctionItem, bidLists }) => {
	//const {auctionBidsList} = useWriteContract();
	const [bidCount, setBidCount] = useState<any>(1);
    const [openBidModal, setOpenBidModal] = useState(false);
    const [openBidListModal, setOpenBidListModal] = useState(false);
    const [auctionId, setAuctionId] = useState(0);
    const [auctionMinPrice, setAuctionMinPrice] = useState(0);
    const [auctionMaxPrice, setAuctionMaxPrice] = useState(0);
	
	//AuctionBidList
    useEffect(()=> {
		setBidCount(bidLists ? bidLists.length : 1)
    },[bidLists])

    const showModal = (item:any) => {
        setOpenBidModal(true);
        setAuctionId(item.key);
        setAuctionMinPrice(item.minPrice);
        setAuctionMaxPrice(item.maxPrice);
    };

	const columns = [
		{
			title: "Auction Id",
			dataIndex: "key",
			key: "key",
		},
		{
			title: "Min Price",
			dataIndex: "minPrice",
			key: "minPrice",
            render: (e: any) =>e+' '+CONSTANT.CURRENCY_CODE,
		},
		{
			title: "Max Price",
			dataIndex: "maxPrice",
			key: "maxPrice",
			render: (e: any) =>e+' '+CONSTANT.CURRENCY_CODE,
		},
        {
			title: "Start Date",
			dataIndex: "startTime",
			key: "startTime",
			render: (e: any) => dateFormatter(
				e,
				CONSTANT.DATE_FORMATS.YMDHA
			),
		},
        {
			title: "End Date",
			dataIndex: "endTime",
			key: "endTime",
			render: (e: any) => dateFormatter(
				e,
				CONSTANT.DATE_FORMATS.YMDHA
			),
		},

		{
			title: "Action",
			key: "action",
			render: (e: any, record: any) => {
				
				const count = bidLists ? Object.keys(bidLists)
				.filter(key => bidLists[key].auctionId ==  record?.key)
				.map((key) => {
					return bidLists[key];
				}, {bidLists}) : [];
				
				return ( 

					e.orderType !== "4" && (
						<Space size="middle">
							<Button
								type="primary"
								className="btn btn_primary"
								key={record.name}
								onClick={() =>
									showModal(record)
								}
							>
								{`Place Bid`} 
							</Button>
                            <Tooltip title={`Auction Bid List`}>
							<Badge showZero count={count ? count.length : 0} size="small">
                                <Button
                                    type="primary"
                                    className="btn btn_primary"
									onClick={() =>
										setOpenBidListModal(record)
									}
                                    
                                >
                                   <EyeOutlined />
                                </Button></Badge>
                            </Tooltip>
						</Space>
					)
				);
			},
		},
	];

	return (
		<>
			<Table
				className="detailsTab detailsTab_table"
				key={"transaction_list"}
				rowClassName="input_group"
				style={{ color: "#fff", background: "transparent" }}
				columns={columns}
				dataSource={auctionItem ? auctionItem : []}
				loading={!auctionItem ? true : false}
			/>
            { openBidModal && <AuctionBid open={openBidModal} setOpen={setOpenBidModal} auctionMinPrice={auctionMinPrice}  auctionMaxPrice={auctionMaxPrice} auctionId={auctionId}/> }
            { openBidListModal && <AuctionBidList isModalOpen={openBidListModal} setOpen={setOpenBidListModal} lists={bidCount > 0 ? bidLists : []} /> }


		</>
	);
};

export default Auctions;

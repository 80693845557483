import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import PageSpinner from "./components/PageSpinner/PageSpinner";
import Router from "./config/RouterConfig";
import useStore from "./store";
import ScrollToTop from "./utils/ScrollToTop";
import { message } from "antd";
import { coinbaseWallet } from "./connectors/coinbaseWallet";
import {  metaMask } from "./connectors/metaMask";
import { useWeb3React } from "@web3-react/core";
import { useSignerOrProvider, useWriteContract } from "./hooks";

const App: React.FC = observer(() => {
	const { signer } = useSignerOrProvider();
	const { AUTH } = useStore();
	const { InitializeApp, app_loading } = AUTH;
	const {account} = useWeb3React();
	const {transferWelcomeCoin} = useWriteContract();

	useEffect(() => {
		async function autoConenct() { 
			const label = localStorage.getItem("connectorId");
			try {
				switch (label) {
					case "MetaMask":
						await metaMask.activate();
						break;
			
					case "Coinbase Wallet":
						await coinbaseWallet.activate();
						break;
			
					default:
						break;
				}
			} catch (error) {
				message.error("Failed to connect wallet. Please try again.");
			}
		}
		autoConenct();
	}, []);
	
	useEffect(()=>{
		const alreadyAddedAcnt = localStorage.getItem("transfered");
		function initWelcomeCoin(account:any) {
			if(signer && account && alreadyAddedAcnt !== account) {
				transferWelcomeCoin(account);
			}			
		}
		const timeoutID = setTimeout(() => {
			initWelcomeCoin(account);
		}, 1500);
		return () => clearTimeout(timeoutID);
	},[account,signer,transferWelcomeCoin])
	  
	useEffect(() => {
		InitializeApp();
	}, [InitializeApp]);
	
	return (
		<BrowserRouter><ScrollToTop />{app_loading ? <PageSpinner /> : <Router />}</BrowserRouter>
	);
});

export default App;

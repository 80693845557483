import { Button, Col, Form, Modal, Radio, Row, message } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import moment from "moment";
import { NftRequest } from "../../../../requests/NftReuqest";
import { useWriteContract } from "../../../../hooks";
import { useWeb3React } from "@web3-react/core";
import { CONSTANT } from "../../../../config/Constant";

const listForSaleData = [
	{
		id: 1,
		title: "Owner",
		desc: "Item is Listed My collection",
	},
	{
		id: 2,
		title: "Auto List Higher Price",
		desc: "The Item is Listed As Auto Hire Price",
	},
	{
		id: 3,
		title: "Timed Action",
		desc: "The Time Is Listed For Action",
	},
];

const MintModal: React.FC<any>= ({
    modalOpen,
	setModalOpen,
	mintQty,
	mintedResponse,
    productData,
	loading
}) => {
	const { account, provider } = useWeb3React();
    const [form] = Form.useForm();
    const [open,setOpen] = useState<boolean>(false);
    const [type,setType] = useState<number>(1);
    const [saving, setSaving] = useState(false);
    const [disabled, setDisabled] = useState(false);
    //const [orderResponse, setOrderResponse] = useState([]);
	const {updateOrderStatus, createAuctions} = useWriteContract();
	
	const orderResponse = mintedResponse && mintedResponse.slice(-mintQty); // 👉️ ['c', 'd', 'e']
	console.log('orderResponse',orderResponse);
	
	useEffect(() => {
        setOpen(modalOpen)
    },[modalOpen]);
    // handle password tool tip visiblility
	const handleChange = () => {
		form
			.validateFields()
			.then(() => {
				setDisabled(false);
			})
			.catch(() => {
				setDisabled(false);
			});
	};

    const onClose = () => {
		setOpen(false);
		setModalOpen(false);
		//setModalOpen(false);
		setType(1);
		form.resetFields();
	};

    // make function call to login
	const handleSubmit = useCallback(async(submitedData: any) => {
		
		
		if (!provider || !account) {
			message.error("Wallet not connected");
			return;
		  }
		  setSaving(true);
		  submitedData.sale= type;
		  if (type === 3) {
			  try {
				  submitedData.start_date_time = moment(submitedData.start_date_time).format("X");
				  submitedData.end_date_time = moment(submitedData.end_date_time).format("X");
				  console.log(orderResponse,account,submitedData);
				await createAuctions(orderResponse,account,submitedData)
			} catch (error: any) {
				const err = error.reason ?? error.message ?? error;
				message.error(`An error occured: ${err}`);
			} finally {
				setSaving(false);
				//setOpen(false);
			}
				
		} else if (type === 2) {
			await updateOrderStatus(orderResponse,account,CONSTANT.ORDER_TRANSFER_TYPE);
		} else if (type === 1) {
			setSaving(false);
			setOpen(false);
			setTimeout(() => {
				window.location.reload();
			}, 500);
		}
	},[account, provider, createAuctions,orderResponse,type,updateOrderStatus]);

    const handleRadioChange = (event: any) => {
		setType(event.target.value);
	};

	
    

    return (
        <>
        <Modal
				title=""
				centered
				open={open}
				onOk={() => setOpen(false)}
				onCancel={onClose}
				maskClosable={false}
				closeIcon={
					<span className="btn btn_primary btn_modalClose" title="Close">
						<span className="iconFont-close"></span>
					</span>
				}
				width={900}
				destroyOnClose={true}
				footer={[
					// <Button
					// 	key="2"
					// 	htmlType="button"
					// 	className="cancelBtn mr-35"
					// 	onClick={close}
					// >
					// 	Cancel
					// </Button>
					<Button
						key="1"
						disabled={loading ? loading : disabled}
						form="forListForm"
						loading={loading ? loading :saving}
						htmlType="submit"
						type="primary"
					>
						Save
					</Button>,
				]}
			>
				<div className="bid_containet">
					<h2 style={{ fontSize: "24px", fontWeight: 600 }}>List For Sale</h2>
					<FormBox
						name="basic"
						form={form}
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						autoComplete="off"
						id="forListForm"
						onChange={handleChange}
						onFinish={handleSubmit}
					>
						{listForSaleData.map((item) => {
							return (
								<div
									className="main-map"
									aria-disabled={saving}
									onClick={() => {
										setType(item.id);
									}}
									key={item.id}
								>
									<div>
										<h3
											style={{
												color: "#FF31CA",
												fontSize: "20px",
												lineHeight: "28.8px",
												marginBottom: "6px",
											}}
										>
											{item.title}
										</h3>
										<p
											style={{
												fontSize: "18px",
												lineHeight: "28.8px",
												margin: 0,
												color: "#ffffff",
											}}
										>
											{item.desc}
										</p>
									</div>
									<Form.Item name="sale">
										<Radio
											
											value={item.id}
											onChange={handleRadioChange}
											checked={type === item.id}
										/>
									</Form.Item>
								</div>
							);
						})}
						{type === 3 && (
							<div>
								<p style={{ fontSize: "20px", color: "#ffffff" }}>
									Lowest And Unique Bid
								</p>

								<Row gutter={30} className="borderWhite">
									<Col xl={12} md={12} xs={24} className="input_group">
										<InputBox.Text
											placeholder="Min Bid Amount"
											label="Minimum Bid Amount"
											name="min_bid_amount"
											suffix={CONSTANT.CURRENCY_CODE}
											rules={[
												() => ({
													validator(rule, value) {
														if (!value) {
															return Promise.reject("Min Amount is required");
														} else if (value && value < Number(productData.price)) {
															return Promise.reject("enter max amount");
														}
														return Promise.resolve();
													},
												}),
											]}
										/>
									</Col>
									<Col xl={12} md={12} xs={24} className="input_group">
										<InputBox.Text
											placeholder="Max Bid Amount"
											label="Maximum Bid Amount"
											name="max_bid_amount"
											suffix="Wei"
											rules={NftRequest.max_bid_amount}
										/>
									</Col>
								</Row>
								<Row gutter={30} className="borderWhite">
									<Col xl={12} md={12} xs={24} className="input_group">
										<label> Start Date And Time</label>
										<InputBox.DateTimePicker
											name="start_date_time"
											rules={NftRequest.start_date}
											disabledDate={(current:any) => { 
												return moment().add('-1', 'days')  >= current;
											}}
										/>
									</Col>
									<Col xl={12} md={12} xs={24} className="input_group">
										<label> End Date And Time</label>
										<InputBox.DateTimePicker
											name="end_date_time"
											rules={NftRequest.end_date}
											disabledDate={(current:any) => { 
												return moment().add(0, 'days')  >= current;
											}}
										/>
									</Col>
								</Row>
								{/* <Row gutter={30} className="borderWhite">
									<Button
										htmlType="button"
										className="cancelBtn mr-35"
										onClick={onClose}
									>
										Cancel
									</Button>
									<Button
										disabled={disabled}
										form="forSaleForm"
										// loading={saving}
										htmlType="submit"
										type="primary"
									>
										Save
									</Button>
								</Row> */}
							</div>
						)}
					</FormBox>
				</div>
			</Modal>
        </>
    );
}


export default MintModal;
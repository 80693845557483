import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import { minName, maxName } from "../config/Validators";

export const NftRequest: RequestProps = {
	title: [
		{ required: true, message: Message().required.text("Title") },
		{ min: minName, message: Message().min.string(minName, "Title") },
		{ max: maxName, message: Message().max.string(maxName, "Title") },
	],
	description: [
		{ required: true, message: Message().required.text("Description") },
		{ min: minName, message: Message().min.string(minName, "Description") },
	],
	image_url_h: [
		// {required: true, message: Message().required.text("Image URL") },
		({ getFieldValue }) => ({
			validator(rules, value) {
				if (!value && !getFieldValue("image_url_d")) {
					return Promise.reject(Message().required.text("Image URL"));
				} else if (!value && getFieldValue("image_url_d")) {
					return Promise.resolve();
				} else if (value && !getFieldValue("image_url_d")) {
					return Promise.resolve();
				}
				return Promise.resolve();
			},
		}),
		{
			pattern: /\.(jpg|png|gif|jpeg|bmp)$/,
			message: Message().required.text("invalid Image URL"),
		},
		{ min: minName, message: Message().min.string(minName, "Image URL") },
		{ type: "url", message: Message().url },
	],
	image_url_d: [
		// { required: true, message: Message().required.text("Image URL") },
		({ getFieldValue }) => ({
			validator(rules, value) {
				if (!value && !getFieldValue("image_url_h")) {
					return Promise.reject(Message().required.text("File"));
				} else if (!value && getFieldValue("image_url_h")) {
					return Promise.resolve();
				} else if (value && !getFieldValue("image_url_h")) {
					return Promise.resolve();
				}
				return Promise.resolve();
			},
		}),
	],
	price: [{ required: true, message: Message().required.text("Price") }],
	quantity: [{ required: true, message: Message().required.text("Quantity") }],
	category: [{ required: true, message: Message().required.text("Catagory") }],
	max_bid_amount : [{ required: true, message: Message().required.text("Max Amount")}],
	start_date : [{ required: true, message: Message().required.text("Date and Time")}],
	end_date : [{ required: true, message: Message().required.text("Date and Time") }],
};

import React from "react";
import { useRoutes } from "react-router-dom";
import AuthLayout from "../views/layouts/AuthLayout";
import ProductDetails from "../views/front_pages/ProductDetails";
import AddProduct from "../views/front_pages/AddProduct";
const PageNotFound = React.lazy(() => import("../views/errors/PageNotFound"));
const Unauthorized = React.lazy(() => import("../views/errors/Unauthorized"));
const HomePage = React.lazy(() => import("../views/front_pages/HomePage"));
const AddToken = React.lazy(() => import("../views/front_pages/AddToken"));
const MyCollection = React.lazy(() => import("../views/front_pages/MyCollection"));
const PrivacyPolicy = React.lazy(() => import("../views/front_pages/Pages/PrivacyPolicy"));
const TermsOfService = React.lazy(() => import("../views/front_pages/Pages/TermsOfService"));
const GeneralRiskWarning = React.lazy(() => import("../views/front_pages/Pages/GeneralRiskWarning"));

export default function Router() {
	const element = useRoutes(RouterConfig);
	return element;
}

// {
			// 	path: "/product-details/:productId",
			// 	element: <ProductDetails />,
			// },
			
export const RouterConfig = [
	{
		element: <AuthLayout />,
		children: [
			{
				path: "/",
				element: <HomePage />,
			},
			{
				//path: "/product-details/:productId",
				path: "/product-details/:productId/:slug",
				element: <ProductDetails />,
			},
			{
				path: "/add-product",
				element: <AddProduct />,
			},
			{
				path: "/add-token",
				element: <AddToken />,
			},
			{
				path: "/my-collections",
				element: <MyCollection />,
			},
			{
				path: "/privacy-policy",
				element: <PrivacyPolicy />,
			},
			{
				path: "/terms-of-service",
				element: <TermsOfService />,
			},
			{
				path: "/general-risk-warning",
				element: <GeneralRiskWarning />,
			},
			{
				path: "/unauthorized",
				element: <Unauthorized />,
			},
			{
				path: "/product-details/:pid",
				element: <PageNotFound />,
			},
			{
				path: "*",
				element: <PageNotFound />,
			},
		],
	}
];

import React,{useCallback, useState} from "react";
import { Button, Table, Tag, Space, Tooltip, message, Spin } from "antd";
import { addressFormatter } from "../../../../../config/Global";
import { useWeb3React } from "@web3-react/core";
import { useWriteContract } from "../../../../../hooks";
import { CONSTANT } from "../../../../../config/Constant";

const Transactions: React.FC<any> = ({ orderItem }) => {
	const { account, provider } = useWeb3React();
	const { tokenTransferProcess,loading } = useWriteContract();
	//const [transferLoader,setTransferLoader] = useState<any>(loading);
	
	
	function ButtonCell(payload:any) {
		const [transferLoader,setTransferLoader] = useState<any>(false);
	
		return transferLoader ? (
		  <Spin />
		) : (
			<Button
			type="primary"
			className="btn btn_primary"
			onClick={() =>
					{
						setTransferLoader(loading)
						openTransferDrawerHandler(payload.orderId,payload.purchasePrice)
					}
			}
		>
			{`Transfer Token`} 
		</Button>
		);
	  };

	const columns = [
		{
			title: "Order Id",
			dataIndex: "orderId",
			key: "orderId",
		},
		{
			title: "Product Id",
			dataIndex: "tokenId",
			key: "tokenId",
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			key: "quantity",
			render: () => 1,
		},
		{
			title: "Price",
			dataIndex: "purchasePrice",
			key: "purchasePrice",
			render: (e: any) => e+' '+CONSTANT.CURRENCY_CODE,
		},
		{
			title: "From",
			dataIndex: "seller",
			key: "seller",
			render: (e: any) => (
				<Tooltip placement="topLeft" title={e}>
					<Tag>{addressFormatter(e)}</Tag>
				</Tooltip>
			),
		},
		{
			title: "To",
			dataIndex: "buyer",
			key: "buyer",
			render: (e: any) => (
				<Tooltip placement="topLeft" title={e}>
					<Tag>{addressFormatter(e)}</Tag>
				</Tooltip>
			),
		},
		{
			title: "Type",
			key: "orderType",
			dataIndex: "orderType",
			render: (e: any) =>
				e !== "4" ? (
					<Tag color="geekblue">{e === 0 ? "Minted" : "Transfer"}</Tag>
				) : (
					<Tag color="red">SOLD</Tag>
				),
		},
		{
			title: "Action",
			key: "action",
			render: (e: any, record: any) => {
				return ( 
					e.orderType !== "4" && (
						<Space size="middle">
							<ButtonCell orderId={e.orderId}  purchasePrice={e.purchasePrice}/>
						</Space>
					)
				);
			},
		},
	];

	const openTransferDrawerHandler = async (id: any, price: any) => {
		if (!provider || !account) {
			message.error("Wallet not connected");
			return;
		}
		const transferPrice = percentage(price);
		await tokenTransferProcess(id,price,transferPrice).then((res: any) => {
			console.log('success!',res);
			
		})
	};

	const percentage = (amount: any) => {
		const per: any = (amount / 100) * 10;
		return parseInt(amount) + parseInt(per);
	};

	return (
		<>
			<Table
				className="detailsTab detailsTab_table"
				key={"transaction_list"}
				rowClassName="input_group"
				style={{ color: "#fff", background: "transparent" }}
				columns={columns}
				dataSource={orderItem ? orderItem : []}
			/>
		</>
	);
};

export default Transactions;

import Web3 from 'web3';

const resolveWeb3 = async () => {

	//const alreadyInjected = typeof web3 !== 'undefined'; // i.e. Mist/Metamask
	// const localProvider = 'http://192.168.1.47:7545';
	//const alchemy_providerUrl = 'https://polygon-mumbai.g.alchemy.com/v2/i8_5uMYtkSkeSAfH00lZ46y6gShoz4VH';
	const alchemy_providerUrl = 'https://polygon-mainnet.g.alchemy.com/v2/ycoUXnNMC8kv355wTaOpYqDwCr_KRusB';
   //const node = 'wss://polygon-mumbai.g.alchemy.com/v2/i8_5uMYtkSkeSAfH00lZ46y6gShoz4VH';
   //const rpcURL = 'https://matic.getblock.io/709a7354-3cd1-4bfa-9231-6dd454c63149/testnet/';
  //const providerUrl = 'https://rpc-mumbai.maticvigil.com/';
 //  const provider = new Web3.providers.HttpProvider(providerUrl);
//    const alchemyUrl = 'wss://eth-mainnet.alchemyapi.io/v2/your-alchemy-key'; // Replace with your Alchemy WebSocket URL
//    const web3 = new Web3(alchemyUrl);
  return new Web3(alchemy_providerUrl);
};

export default resolveWeb3;
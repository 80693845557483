import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "antd/dist/antd.less";
import "./assets/styles/main.less";
import App from "./App";
import PageSpinner from "./components/PageSpinner/PageSpinner";
import { Web3ReactProvider } from "@web3-react/core";
import connectors from "./connectors";
import { ConfigProvider } from "antd";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<Suspense fallback={<PageSpinner />}>
		<ConfigProvider>
			<Web3ReactProvider connectors={connectors}>
				<App /> 
			</Web3ReactProvider>
		</ConfigProvider>
	</Suspense> 
);
 
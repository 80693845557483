import React from 'react'
import { Table, Tooltip } from 'antd';
import { addressFormatter,dateFormatter } from "../../../../../config/Global";
import { CONSTANT } from "../../../../../config/Constant";
import { Link } from 'react-router-dom';

const Assets: React.FC<any> = ({orderItem}) => {
    
    const columns = [
        {
          title: 'Transactions',
          dataIndex: 'transactionHash',
          key: 'transactionHash',
          render: (text:any) => <Tooltip placement="topLeft" title={text}><a target='_blank' rel="noreferrer"  href={`${CONSTANT.RPC_URL}/tx/${text}`} >{text.slice(0, 10)}</a></Tooltip>,
        },
        {
          title: 'From',
          dataIndex: 'from',
          key: 'returnValues',
          render: (_:any, { returnValues }:any) => (
            <Tooltip placement="topLeft" title={returnValues.receiver}><Link to={'#'}>{addressFormatter(returnValues.receiver)}</Link></Tooltip>
          ),
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'returnValues',
            render: (_:any, { returnValues }:any) => (
              <Tooltip placement="topLeft" title={returnValues.sender}><Link to={'#'}>{addressFormatter(returnValues.sender)}</Link></Tooltip>
            ),
        },
        {
          title: 'Amount',
          dataIndex: 'returnValues',
          render: (returnValues:any) => returnValues.amount +' '+CONSTANT.CURRENCY_CODE,
        },
        {
            title: 'Date',
            dataIndex: 'returnValues',
            render: (_:any, { returnValues }:any) => (
                dateFormatter(returnValues.created,CONSTANT.DATE_FORMATS.YMDHA)
              ),
          }
    ];

    return (
        <>
          <Table className="detailsTab detailsTab_table" 
            key={'o_list'}
            rowClassName="input_group"
            style={{ color:'#fff', background:'transparent' }}
            columns={columns} 
            dataSource={orderItem} 
          />
        </>
    )
}

export default Assets

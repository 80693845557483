import React, { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button,message } from "antd";
import { categoryNameById } from "../../../../config/Global";
import { useWriteContract } from "../../../../hooks";
import MintModal from "./MintModal";
import { CONSTANT } from "../../../../config/Constant";

const ProductInfo: React.FC<any> = ({ data, availableForMint }) => {
	const { account, provider } = useWeb3React();
	const { mintProcess,loading,modelBox,mintResponse } = useWriteContract();
	const [mintQty, setMintQty] = useState(1);
	const [modalOpen, setModalOpen] = useState(modelBox);
	const [showFullDescription, setShowFullDescription] = useState(false);

	const toggleDescription = () => {
		setShowFullDescription(!showFullDescription);
	};

	const truncateDescription = (text:any, length:any) => {
		if (text.length <= length) return text;
		const trimmedText = text.slice(0, length);
		const lastSpaceIndex = trimmedText.lastIndexOf(" ");
		return lastSpaceIndex === -1 ? trimmedText : trimmedText.slice(0, lastSpaceIndex);
	  };


	const increment = useCallback(() => {
		if (mintQty >= availableForMint) return false;
		setMintQty(function (prevMintQty) {
			return (prevMintQty += 1);
		});
	}, [availableForMint,mintQty]);

	const decrement = useCallback(() => {
		setMintQty(function (prevMintQty) {
			if (prevMintQty > 1) {
				return (prevMintQty -= 1);
			} else {
				return (prevMintQty = 1);
			}
		});
	}, []);

	const  mintProcessHandler = async () => {
		if (!provider || !account) {
		  message.error("Wallet not connected");
		  return;
		}
		await mintProcess(data,account,mintQty);
	}


	const QntBox = () => {
		return (
			<div className="counter_wrap">
				<button onClick={decrement}>-</button>
				<span>{mintQty}</span>
				<button onClick={increment}>+</button>
			</div>
		);
	};	

	useEffect(()=> {
		setModalOpen(modelBox)
	},[modelBox]);
	return (
		<>
			{
				modalOpen && (<MintModal modalOpen={modalOpen} setModalOpen={setModalOpen} mintQty={mintQty} mintedResponse={mintResponse} productData={data} loading={loading} />)
			}
			
			<h1>
				{data.title} <span>{categoryNameById(data.category)}</span>
			</h1>

			<h2>Description</h2>
			<div className="content">
				<p>
				{
					showFullDescription ?  data.description  :  truncateDescription(data.description, CONSTANT.PRODUCT_DETAIL.DESCRIPTION_LIMIT)
				}
				&nbsp; &nbsp;
				{ 
					data.description.length > CONSTANT.PRODUCT_DETAIL.DESCRIPTION_LIMIT  
					? 
						<h3><span className="readmore" onClick={toggleDescription}>{showFullDescription ? 'Read Less' : 'Read More'}</span></h3>
					: ''
				}
				</p>
			</div>
			<h3>Price</h3>
			<strong> {data.price} {CONSTANT.CURRENCY_CODE} </strong>
			<br />
			<br />

			<Button
				type="primary"
				className="btn btn_primary"
				loading={loading}
				onClick={ () => mintProcessHandler() }
			>
				BUY <ShoppingCartOutlined />
			</Button>
			<QntBox />
			
			
		</>
	);
};

export default ProductInfo;

import React from "react";
import { Col, Row } from "antd";
// import { dateFormatter } from "../../../../../config/Global";
import { CONSTANT } from "../../../../../config/Constant";

const Details: React.FC<any> = ({
	data,
	availableForMint
}) => {
	
	const content1 = [
		{
			colSize: 12,
			title: "Creator",
			subText: data.creator,
		},
		
		{
			colSize: 12,
			title: "Quantity",
			subText: `${data.quantity}`,
		},
		{
			colSize: 12,
			title: "Melts",
			subText:`${data.quantity - availableForMint}`,
		},
		{
			colSize: 12,
			title: "Available Quantity",
			subText: `${availableForMint}`,
		},
		{
			colSize: 12,
			title: "Price",
			subText: `${data.price} ${CONSTANT.CURRENCY_CODE}`,
		},
	];
	const content2 = [
		{
			colSize: 12,
			title: "Royalty Fee",
			subText: "8%",
		},
		{
			colSize: 12,
			title: "Melt Fee",
			subText: "2%",
		},
		{
			colSize: 12,
			title: "Standard",
			subText: "ERC-1155",
		},
	];

	return (
		<>
			<Row className="detailsTab">
				{content1.map((value: any, i: any) => (
					<Col
						xs={24}
						lg={value.colSize}
						key={"det_" + i}
						className="input_group"
					>
						<small>{value.title}</small>
						<p className="input">{value.subText}</p>
					</Col>
				))}
			</Row>

			<Row className="detailsTab">
				{content2.map((value: any, i: any) => (
					<Col
						xs={24}
						lg={value.colSize}
						key={"detn_" + i}
						className="input_group"
					>
						<small>{value.title}</small>
						<p className="input">{value.subText}</p>
					</Col>
				))}
			</Row>
		</>
	);
};

export default Details;

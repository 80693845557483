export const CONSTANT = {
  DATE_TIME_FORMAT: "DD MMM yyyy hh:mm A",
  HMS_DATE_FORMAT: "DD MM YYYY HH:mm:ss",
  DATE_FORMAT: "DD MMM yyyy",
  BACKEND_DATE_FORMAT: "YYYY-MM-DD",
  ACTIVE_NETWORK_ID: 137,
  CURRENCY_CODE: 'ESPC',
  RPC_URL: "https://polygonscan.com",
  STORAGE_CONTRACT_ADDRESS: "0x2AB1911F14826e9fE4284D58EF44dbA38776C1ba",
  AUCTION_QUICK_LIST: "auction_lists",

  //WITHOUT ESPRO 
  // PRODUCT_CONTRACT_ADDRESS: "0x43087786d841b67669B9467F7d38bbE42Df09189",
  // ORDER_CONTRACT_ADDRESS: "0x6874941a72186bDfB372CD6e146B6b680A00Dec0",
  // AUCTION_CONTRACT_ADDRESS: "0x62B6A094322D2dC6a41E85F208cB0F0866cbc398",
  // ESPRO_CONTRACT_ADDRESS: '0x91c1e0B7C506f14fe51dF9A3E3f1155d756e2443', //0x6de2fAB32C06C6c935f457479721c3b09bCc421A
  // ESPRO_WALLAT_CONTRACT_ADDRESS: '0x1a820f7C94618A9440d13BF7f6827131726A1625',
  // TOKEN_CONTRACT_OWNER_ADDRESS: '0x6E4bd947B7eEF2323df8936692Cc8FA40A34B12B',

  //WITH ESPRO 
  PRODUCT_CONTRACT_ADDRESS: "0xc2a0fea6b4b2f651fcef66d392e23e744ebccfd2",
  ORDER_CONTRACT_ADDRESS: "0x9721cd4342ef21168bb346a576edf1f2c5901e1d",
  AUCTION_CONTRACT_ADDRESS: "0xfe3e595b3839f18176d1d14d6d6609231b23dde1",
  ESPRO_CONTRACT_ADDRESS: '0xc609C1e6205eCA3B683B56ec42C5DD9f8E65605C',
  ESPRO_WALLAT_CONTRACT_ADDRESS: '0xdcbec1256df9e02f2d4189030c1af1bc4e18b843',
  TOKEN_CONTRACT_OWNER_ADDRESS: '0x6E4bd947B7eEF2323df8936692Cc8FA40A34B12B',

  ORDER_OWNER_TYPE: '0', // default after mint status
  ORDER_TRANSFER_TYPE: '1', // order status for available transfer
  ORDER_AUCTION_TYPE: '2', // order status for auction
  ESPRO_COIN:{
    IMAGE_URL: "http://placekitten.com/200/300",
    SYMBOL: "ESPC",
    DECIMAL: 18,
  },
  LOCALSTORAGE_TOKEN:{
    ACTIVE_TAB: "active_tab",
    DEFAULT_PRODUCT_DETAIL_TAB: '2'
  },
  PRODUCT_DETAIL:{
    DESCRIPTION_LIMIT:'600'
  },
  PRODUCT_EXCLUDE:['4','29','68','79'],
  DATE_FORMATS: {
    DMY: "DD MM YYYY",
    DMYHM: "DD MM YYYY HH:mm",
    DMYHMS: "DD MM YYYY HH:mm:ss",
    DMYHMA: "DD MM YYYY hh:mm A",
    DMYHMa: "DD MM YYYY hh:mm a",
    YMD: "YYYY MM DD",
    YMDHM: "YYYY MM DD HH:mm",
    YMDHA: "DD-MM-YYYY hh:mm A",
    YMDHMS: "YYYY MM DD HH:mm:ss",
    DMY_dash: "DD-MM-YYYY",
    MDY_dash: "MM-DD-YYYY",
    YMDHM_dash: "YYYY-MM-DD HH:mm",
    YMDHMS_dash: "YYYY-MM-DD HH:mm:ss",
    YMD_dash: "YYYY-MM-DD",
    DMY_slash: "DD/MM/YYYY",
  },
  TIME_FORMATS: {
    HM: "HH:mm",
    HMA: "hh:mm A",
    HMa: "hh:mm a",
    HMS: " HH:mm:ss",
  },
  NFT_CATEGORY: {
    ART: "Art",
    COLLECTIBLES: "Collectibles",
    VIRTUAL_REAL_ESTATE: "Virtual Real Estate",
    GAMING: "Gaming",
    //MUSIC: "Music",
    SPORTS: "Sports",
    VIRTUAL_FASHION: "Virtual Fashion",
    DOMAIN_NAMES: "Domain Names",
    VIRTUAL_GOODS: "Virtual Goods",
    DIGITAL_IDENTITY: "Digital Identity",
    VTIRTUAL_EVENTS : "Virtual Events",
    CHARITABLE_AND_SOCIAL_IMPACT: "Charitable and Social Impact"
  },
  // NFT_CATEGORY_ARRAY: [
  //   "Select Category",
  //   "Art",
  //   "Collectibles",
  //   "Virtual Real Estate",
  //   "Gaming",
  //   "Music",
  //   "Sports",
  //   "Virtual Fashion",
  //   "Domain Names",
  //   "Virtual Goods",
  //   "Digital Identity",
  //   "Virtual Events",
  //   "Charitable and Social Impact"
  // ],

  NFT_CATEGORY_ARRAY: [
    {key: 0, label:  "All Projects", breakloop: false},
    {key: 1, label:  "Art", breakloop: false},
    {key: 2, label:"Collectibles", breakloop: false},
    {key: 3, label:"Virtual Real Estate", breakloop: false},
    {key: 4, label:"Gaming", breakloop: false},
    // {key: 5, label:"Music"},
    {key: 6, label:"Sports", breakloop: true},
    {key: 7, label:"Virtual Fashion", breakloop: true},
    {key: 8, label:"Domain Names", breakloop: true},
    {key: 9, label:"Virtual Goods", breakloop: true},
    {key: 10, label:"Digital Identity", breakloop: true},
    {key: 11, label:"Virtual Events", breakloop: true},
    {key: 12, label:"Charitable and Social Impact", breakloop: true}
  ],

  EXCLUDE_CATEGORY_FROM_LIST:["Music"],

  REDIRECTION_URL:[
    { id : 80, slug : 'digitalpersonaportraits' },
    { id : 92, slug : 'pixelpetsparade' },
    { id : 55, slug : 'virtualidentityvault' },
    { id : 23, slug : 'virtual-reality-realm' },
    { id : 32, slug : 'virtual-food-festival-flavors-around-the-screen' },
    { id : 22, slug : 'gamefi-galaxy' },
    { id : 48, slug : 'virtualdomainsvr' },
    { id : 56, slug : 'pixelpowerups' },
    { id : 53, slug : 'digitalartifactx' },
    { id : 95, slug : 'etherwebestates' },
    { id : 5, slug : 'monkey-nako' },
    { id : 47, slug : 'avatarauthenticity' },
    { id : 43, slug : 'cryptocare-collective' },
    { id : 108, slug : 'artistic-activism' },
    { id : 36, slug : 'scary-baby-hobbit' },
    { id : 75, slug : 'nftidentitycanvas' },
    { id : 20, slug : 'digital-playground' },
    { id : 81, slug : 'blockchainidshield' },
    { id : 3, slug : 'happy-ape' },
    { id : 33, slug : 'virtual-wine-tasting-wine--webinars' },
    { id : 8, slug : 'holographic-jewelry' },
    { id : 28, slug : 'cyber-cricket' },
    { id : 44, slug : 'cryptotlds' },
    { id : 30, slug : 'bitboutique-emporium' },
    { id : 2, slug : 'bored-ape' },
    { id : 38, slug : 'princess-diana-inspired-gown' },
    { id : 14, slug : 'metaverseopolis' },
    { id : 65, slug : 'cyberpropertyx' },
    { id : 37, slug : 'skeleton-flower-pot' },
    { id : 106, slug : 'artistic-altruism' },
    { id : 19, slug : 'gameverse' },
    { id : 73, slug : 'cryptopassport' },
    { id : 66, slug : 'nftwardrobe' },
    { id : 15, slug : 'decentralized-city' },
    { id : 39, slug : 'nature-inspired-leaf-earrings' },
    { id : 76, slug : 'virtualdnaprofile' },
    { id : 85, slug : 'digitalfurniturefix' },
    { id : 89, slug : 'epicgamegearvault' },
    { id : 90, slug : 'nftconcertcraze' },
    { id : 70, slug : 'cosmiccraftables' },
    { id : 96, slug : 'virtualnftdomains' },
    { id : 86, slug : 'metaversemementos' },
    { id : 97, slug : 'cryptourls' },
    { id : 61, slug : 'nftdomainquest' },
    { id : 49, slug : 'digitalpersonakeys' },
    { id : 11, slug : 'pudgy-penguins' },
    { id : 16, slug : 'cryptoverse' },
    { id : 69, slug : 'vrrealestategems' },
    { id : 12, slug : 'axie-infinity' },
    { id : 60, slug : 'pixeldomains' },
    { id : 40, slug : 'the-modern-muse-jumpsuit' },
    { id : 77, slug : 'esignatureseals' },
    { id : 50, slug : 'impactful-tokens' },
    { id : 10, slug : 'moonbirds' },
    { id : 63, slug : 'metanameregistry' },
    { id : 34, slug : 'virtual-fitness-challenge-virtualfit-fest' },
    { id : 13, slug : 'cryptopunks' },
    { id : 62, slug : 'digitaldomein' },
    { id : 42, slug : 'art-for-change' },
    { id : 18, slug : 'genesis' },
    { id : 72, slug : 'cyberpetscraze' },
    { id : 54, slug : 'bitblocktitles' },
    { id : 17, slug : 'cyberia' },
    { id : 78, slug : 'cybercitizenship' },
    { id : 27, slug : 'crypto-ball' },
    { id : 7, slug : 'digital-rose-dream-gown' },
    { id : 21, slug : 'limitless-gaming' },
    { id : 51, slug : 'domaindynasty' },
    { id : 35, slug : 'boho-chic-maxi-dress' },
    { id : 59, slug : 'diginamevault' },
    { id : 100, slug : 'etherealdomains' },
    { id : 41, slug : 'the-crimson-cascade-dress' },
    { id : 103, slug : 'nftnetnexus' },
    { id : 91, slug : 'virtualcuisinedelight' },
    { id : 83, slug : 'nftcitizenship' },
    { id : 87, slug : 'magicmysteryboxes' },
    { id : 84, slug : 'cryptovisagecards' },
    { id : 98, slug : 'nftwebregistry' },
    { id : 99, slug : 'blockchainbiosphere' },
    { id : 94, slug : 'blockchain-philanthropy' },
    { id : 88, slug : 'cryptocomicscapsules' },
    { id : 52, slug : 'biometricbadge' },
    { id : 82, slug : 'biometricsignatureart' },
    { id : 57, slug : 'identitymosaic' }
  ],

  SEO:{
    META_DETAILS:[
      {
        "ID": 1,
        "Title": "Immerse Yourself in VR with EsproNFT's VR Zombie Experience",
        "Description": "Explore the thrilling world of virtual reality with EsproNFT's VR Zombie adventure. Unleash the undead in a captivating VR experience in spine-chilling journey."
      },
      {
        "ID": 2,
        "Title": "Discover Unique NFT Art-EsproNFT's Bored Ape Collection",
        "Description": "Dive into the world of digital art with EsproNFT's Bored Ape collection. Explore unique NFTs, each a masterpiece of interesting creativity. Collect yours today."
      },
      {
        "ID": 3,
        "Title": "Capture Joy in Every Pixel-EsproNFT's Happy Ape NFT Collection",
        "Description": "Experience pure happiness with EsproNFT's Happy Ape NFT collection. Discover and collect joyful digital art pieces that bring smiles to your digital world. Explore now"
      },
      {
        "ID": 5,
        "Title": "Monkey Nako NFT-Playful Digital Art by EsproNFT",
        "Description": "Dive into the whimsical world of Monkey Nako NFTs by EsproNFT. Explore playful digital art that adds a touch of joy and creativity to your collection. Discover now"
      },
      {
        "ID": 6,
        "Title": "The Simpson NFT Collection-Digital Art Excellence by EsproNFT",
        "Description": "Immerse yourself in The Simpson NFT Collection by EsproNFT. Elevate your digital art collection with these exceptional pieces. Explore and acquire yours now"
      },
      {
        "ID": 7,
        "Title": "Digital Rose Dream Gown- NFT Fashion Elegance at EsproNFT",
        "Description": "Step into a world of digital elegance with EsproNFT's Digital Rose Dream Gown NFT. Own a virtual masterpiece of fashion. Explore and acquire yours today"
      },
      {
        "ID": 8,
        "Title": "Dazzling Holographic Jewelry- EsproNFT's Digital Elegance",
        "Description": "Adorn yourself with EsproNFT's Holographic Jewelry. Explore the mesmerizing blend of digital art and elegance. Own a piece of the future. Discover now."
      },
      {
        "ID": 9,
        "Title": "World of Women NFT Collection- EsproNFT's Empowering Art",
        "Description": "Celebrate the strength and beauty of women with EsproNFT's World of Women collection. Explore empowering NFT art that reflects the essence of femininity. Discover now."
      },
      {
        "ID": 10,
        "Title": "Moonbirds NFT Collection- EsproNFT's Celestial Artistry",
        "Description": "Embark on a celestial journey with EsproNFT's Moonbirds collection. Discover ethereal NFT art that captures the magic of the night sky. Acquire your cosmic masterpiece now"
      },
      {
        "ID": 11,
        "Title": "Pudgy Penguins NFT Collection-EsproNFT's Charming Arctic Treasures",
        "Description": "Dive into the adorable world of Pudgy Penguins by EsproNFT. Collect charming NFT art featuring these lovable arctic treasures. Start your collection now"
      },
      {
        "ID": 12,
        "Title": "Axie Infinity NFT Collection-EsproNFT's Virtual Wonderland",
        "Description": "Explore the enchanting realm of Axie Infinity with EsproNFT. Dive into a virtual wonderland with unique and captivating NFT collectibles. Discover yours today"
      },
      {
        "ID": 13,
        "Title": "Cryptopunks NFT Collection- EsproNFT's Digital Revolution",
        "Description": "Join the digital revolution with EsproNFT's Cryptopunks collection. Own iconic NFTs representing the essence of the crypto era. Explore and acquire yours now"
      },
      {
        "ID": 14,
        "Title": "Metaverseopolis NFT Collection-EsproNFT's Virtual Cityscapes",
        "Description": "Experience the future of art with EsproNFT's Metaverseopolis collection. Own NFTs that depict mesmerizing virtual cityscapes. Explore and acquire your digital masterpiece now"
      },
      {
        "ID": 15,
        "Title": "Decentralized City NFT-Explore Urban Dreams at EsproNFT",
        "Description": "Step into the visionary world of EsproNFT's Decentralized City collection. Own NFTs capturing the essence of urban dreams. Discover and collect yours now!"
      },
      {
        "ID": 16,
        "Title": "Cryptoverse NFT Collection-EsproNFT's Digital Frontier",
        "Description": "Embark on a journey through the Cryptoverse with EsproNFT. Explore groundbreaking NFTs that define the digital frontier. Secure your piece of the virtual realm now"
      },
      {
        "ID": 17,
        "Title": "Cyberia NFT Collection- EsproNFT's Futuristic Art Odyssey",
        "Description": "Embark on a futuristic art odyssey with EsproNFT's Cyberia collection. Discover cutting-edge NFTs that redefine the digital landscape. Dive into Cyberia now!"
      },
      {
        "ID": 18,
        "Title": "Genesis NFT Collection- EsproNFT's Origin of Digital Art",
        "Description": "Witness the origin of digital art with EsproNFT's Genesis collection. Explore groundbreaking NFTs that mark the genesis of a new era in creativity. Collect yours now"
      },
      {
        "ID": 19,
        "Title": "Gameverse NFT Collection- EsproNFT's Gaming Fantasy",
        "Description": "Dive into EsproNFT's Gameverse collection, a realm of gaming fantasy in NFT form. Explore unique pieces that bring virtual worlds to life. Start your collection now"
      },
      {
        "ID": 20,
        "Title": "Digital Playground NFT Collection-EsproNFT's Creative Haven",
        "Description": "Explore EsproNFT's Digital Playground collection, a haven of creative wonders. Immerse yourself in unique NFTs shaping the future of digital art. Discover and collect now!"
      },
      {
        "ID": 21,
        "Title": "Limitless Gaming NFTs-EsproNFT's Gateway to Infinite Adventures",
        "Description": "Enter the realm of infinite adventures with EsproNFT's Limitless Gaming collection. Explore unique NFTs that redefine the boundaries of virtual gaming. Dive in now"
      },
      {
        "ID": 22,
        "Title": "GameFi Galaxy NFTs-EsproNFT's Cosmic Gaming Collection",
        "Description": "Embark on a cosmic gaming journey with EsproNFT's GameFi Galaxy collection. Explore NFTs that redefine the GameFi experience. Own a piece of the digital galaxy now"
      },
      {
        "ID": 23,
        "Title": "Virtual Reality Realm NFTs-EsproNFT's Gateway to VR Wonders",
        "Description": "Step into the wonders of virtual reality with EsproNFT's VR Realm collection. Explore NFTs that bring the magic of VR to life. Dive into the digital realm now"
      },
      {
        "ID": 24,
        "Title": "NFTPlays Collection-EsproNFT's Playful Digital Art",
        "Description": "Indulge in the playful charm of NFTPlays by EsproNFT. Explore and collect digital art that brings joy and creativity to your digital space. Discover NFTPlays now"
      },
      {
        "ID": 25,
        "Title": "Metaverse League NFTs- EsproNFT's Digital Championship",
        "Description": "Enter the digital championship with EsproNFT's Metaverse League collection. Explore exclusive NFTs that capture the essence of virtual competition. Join the league now"
      },
      {
        "ID": 26,
        "Title": "Metaverse Athletes NFTs-EsproNFT's Digital Sports Stars",
        "Description": "Celebrate the prowess of digital sports with EsproNFT's Metaverse Athletes collection. Explore NFTs capturing the spirit of virtual athleticism. Collect yours now"
      },
      {
        "ID": 27,
        "Title": "Crypto Ball NFTs- EsproNFT's Digital Sports Fantasy",
        "Description": "Dive into the world of digital sports fantasy with EsproNFT's Crypto Ball collection. Explore and collect unique NFTs capturing the thrill of virtual competition. Discover now"
      },
      {
        "ID": 28,
        "Title": "Cyber Cricket NFTs-EsproNFT's Digital Cricketing Glory",
        "Description": "Experience the glory of digital cricket with EsproNFT's Cyber Cricket collection. Explore and collect NFTs that capture the essence of virtual cricketing excitement. Dive in now!"
      },
      {
        "ID": 30,
        "Title": "BitBoutique Emporium NFTs- EsproNFT's Digital Elegance",
        "Description": "Discover digital elegance at its finest with EsproNFT's BitBoutique Emporium collection. Explore unique NFTs that redefine the world of virtual fashion. Elevate your collection now"
      },
      {
        "ID": 31,
        "Title": "Cloud Castle Haven NFTs-EsproNFT's Dreamy Digital Escapade",
        "Description": "Embark on a dreamy digital escapade with EsproNFT's Cloud Castle Haven collection. Explore NFTs that transport you to a whimsical world of enchantment. Dive in now"
      },
      {
        "ID": 32,
        "Title": "Virtual Food Festival-Flavors Around the Screen",
        "Description": "Savor the taste of EsproNFT's Virtual Food Festival collection. Immerse yourself in flavors around the screen with delectable NFTs. Explore and indulge now"
      },
      {
        "ID": 33,
        "Title": "Virtual Wine Tasting- Wine & Webinars Experience",
        "Description": "Indulge in a virtual wine-tasting journey with EsproNFT. Join the Wine & Webinars experience, explore exquisite NFTs, and elevate your digital collection. Discover now"
      },
      {
        "ID": 34,
        "Title": "VirtualFit Fest-Join the Virtual Fitness Challenge",
        "Description": "Embrace the Virtual Fitness Challenge with EsproNFT's VirtualFit Fest. Explore NFTs that inspire wellness and join the digital fitness revolution. Participate now"
      },
      {
        "ID": 35,
        "Title": "Boho Chic Maxi Dress NFT- EsproNFT's Digital Fashion Elegance",
        "Description": "Elevate your digital wardrobe with EsproNFT's Boho Chic Maxi Dress NFT. Explore and own a unique piece of digital fashion elegance. Upgrade your collection now"
      },
      {
        "ID": 36,
        "Title": "Scary Baby Hobbit NFT-EsproNFT's Whimsically Spooky Art",
        "Description": "Delve into whimsical spookiness with EsproNFT's Scary Baby Hobbit collection. Explore and collect uniquely eerie NFTs that add a touch of frightful charm. Discover now!"
      },
      {
        "ID": 37,
        "Title": "Skeleton Flower Pot NFT-EsproNFT's Hauntingly Beautiful Decor",
        "Description": "Add a touch of haunting beauty to your digital space with EsproNFT's Skeleton Flower Pot NFT. Explore and collect uniquely eerie decor pieces. Discover now"
      },
      {
        "ID": 38,
        "Title": "Princess Diana-Inspired Gown NFT- EsproNFT's Royal Elegance",
        "Description": "Own a piece of royal elegance with EsproNFT's Princess Diana-Inspired Gown NFT. Explore and collect a digital masterpiece inspired by timeless grace. Discover now"
      },
      {
        "ID": 39,
        "Title": "Nature-Inspired Leaf Earrings NFT-EsproNFT's Digital Elegance",
        "Description": "Adorn yourself with EsproNFT's Nature-Inspired Leaf Earrings NFT. Explore and collect digital elegance inspired by the beauty of nature. Elevate your collection now!"
      },
      {
        "ID": 40,
        "Title": "Modern Muse Jumpsuit NFT-EsproNFT's Digital Fashion Statement",
        "Description": "Make a digital fashion statement with EsproNFT's Modern Muse Jumpsuit. Explore and collect a unique piece of contemporary style for your digital wardrobe. Discover now"
      },
      {
        "ID": 41,
        "Title": "Crimson Cascade Dress NFT-EsproNFT's Digital Elegance",
        "Description": "Elevate your digital wardrobe with EsproNFT's Crimson Cascade Dress NFT. Explore and collect a unique piece of digital elegance. Redefine your style in the virtual realm!"
      },
      {
        "ID": 42,
        "Title": "Art for Change NFTs-EsproNFT's Creative Impact",
        "Description": "Experience the creative impact of EsproNFT's Art for Change collection. Explore and collect NFTs that contribute to positive change. Support art with a purpose. Discover now"
      },
      {
        "ID": 43,
        "Title": "CryptoCare Collective NFTs-EsproNFT's Philanthropic Art",
        "Description": "Engage in philanthropy through EsproNFT's CryptoCare Collective. Explore and collect NFTs that contribute to charitable causes. Make art with a purpose. Join the collective now"
      },
      {
        "ID": 44,
        "Title": "CryptoTLDs NFTs- EsproNFT's Digital Domain Revolution",
        "Description": "Join the digital domain revolution with EsproNFT's CryptoTLDs collection. Explore and collect NFTs representing the future of online presence. Secure your piece now!"
      },
      {
        "ID": 45,
        "Title": "CryptoCollectibits NFTs-EsproNFT's Byte-Sized Wonders",
        "Description": "Discover byte-sized wonders with EsproNFT's CryptoCollectibits collection. Explore a"
      },
      {
        "ID": 46,
        "Title": "Virtual Treasure Hunt NFTs-EsproNFT's Digital Adventure",
        "Description": "Embark on a digital adventure with EsproNFT's Virtual Treasure Hunt collection. Explore and collect NFTs that unfold the thrill of a virtual quest. Start your journey now!"
      },
      {
        "ID": 47,
        "Title": "Avatar Authenticity NFTs- EsproNFT's Digital Identity",
        "Description": "Define your digital identity with EsproNFT's Avatar Authenticity collection. Explore and collect NFTs that reflect the essence of your virtual self. Discover now"
      },
      {
        "ID": 48,
        "Title": "Virtual Domains VR NFTs- EsproNFT's Immersive Cyber Realms",
        "Description": "Immerse yourself in EsproNFT's Virtual Domains VR collection. Explore NFTs that create immersive cyber realms, redefining the virtual experience. Dive into the digital future"
      },
      {
        "ID": 49,
        "Title": "Digital Persona Keys NFTs-EsproNFT's Unique Cyber Identities",
        "Description": "Craft your unique cyber identity with EsproNFT's Digital Persona Keys collection. Explore and collect NFTs that unlock the essence of your digital self. Define your identity now"
      },
      {
        "ID": 50,
        "Title": "Impactful Tokens NFTs- EsproNFT's Catalyst for Change",
        "Description": "Ignite change with EsproNFT's Impactful Tokens collection. Explore and collect NFTs that serve as a catalyst for positive impact. Join the movement now!"
      },
      {
        "ID": 51,
        "Title": "Domain Dynasty NFTs-EsproNFT's Virtual Kingdoms",
        "Description": "Build your virtual kingdom with EsproNFT's Domain Dynasty collection. Explore and collect NFTs that shape the digital landscape. Establish your domain now"
      },
      {
        "ID": 52,
        "Title": "Biometric Badge NFTs-EsproNFT's Secure Digital Identity",
        "Description": "Secure your digital identity with EsproNFT's Biometric Badge collection. Explore and collect NFTs that redefine digital security. Own your biometric badge now!"
      },
      {
        "ID": 53,
        "Title": "DigitalArtifactX NFTs- EsproNFT's Unique Digital Artifacts",
        "Description": "Discover unique digital artifacts with EsproNFT's DigitalArtifactX collection. Explore and collect NFTs that redefine the essence of digital treasures. Unearth yours now!"
      },
      {
        "ID": 54,
        "Title": "BitBlockTitles NFTs- EsproNFT's Digital Title Revolution",
        "Description": "Revolutionize your digital presence with EsproNFT's BitBlockTitles collection. Explore and collect NFTs that redefine the concept of digital titles. Claim yours now!"
      },
      {
        "ID": 55,
        "Title": "Virtual Identity Vault NFTs- EsproNFT's Secure Digital Haven",
        "Description": "Secure your digital presence with EsproNFT's Virtual Identity Vault collection. Explore and collect NFTs that redefine digital security. Safeguard your identity now!"
      },
      {
        "ID": 56,
        "Title": "Pixel Power-Ups NFTs- EsproNFT's Digital Game Enhancers",
        "Description": "Level up your digital game with EsproNFT's Pixel Power-Ups collection. Explore and collect NFTs that enhance your virtual experience. Boost your digital power now"
      },
      {
        "ID": 57,
        "Title": "Identity Mosaic NFTs-EsproNFT's Unique Digital Tapestry",
        "Description": "Craft your unique digital identity with EsproNFT's Identity Mosaic collection. Explore and collect NFTs that shape a personalized digital tapestry. Define your identity now"
      },
      {
        "ID": 58,
        "Title": "NFTs for Good-EsproNFT's Positive Impact Collection",
        "Description": "Join the movement with EsproNFT's NFTs for Good collection. Explore and collect NFTs that contribute to positive change. Make an impact with your digital collection now"
      },
      {
        "ID": 59,
        "Title": "DigiNameVault NFTs- EsproNFT's Secure Digital Identity",
        "Description": "Secure your digital identity with EsproNFT's DigiNameVault collection. Explore and collect NFTs that redefine digital security. Safeguard your online presence now!"
      },
      {
        "ID": 60,
        "Title": "Pixel Domains NFTs-EsproNFT's Digital Real Estate",
        "Description": "Explore the world of digital real estate with EsproNFT's Pixel Domains collection. Collect NFTs that redefine the concept of virtual property ownership. Secure yours now"
      },
      {
        "ID": 61,
        "Title": "NFT Domain Quest-EsproNFT's Digital Realms Exploration",
        "Description": "Embark on a quest through digital realms with EsproNFT's NFT Domain Quest collection. Explore and collect NFTs that redefine the concept of virtual property. Start your quest now"
      },
      {
        "ID": 62,
        "Title": "Digital Domein NFTs- EsproNFT's Virtual Property Revolution",
        "Description": "Revolutionize your digital presence with EsproNFT's Digital Domein collection. Explore and collect NFTs that redefine the concept of virtual property ownership. Secure your digital domain now"
      },
      {
        "ID": 63,
        "Title": "MetaName Registry NFTs-EsproNFT's Digital Identity Vault",
        "Description": "Secure your digital identity with EsproNFT's MetaName Registry collection. Explore and collect NFTs that redefine digital identity. Safeguard your online presence now"
      },
      {
        "ID": 64,
        "Title": "Domain Crafters NFTs-EsproNFT's Digital Property Artisans",
        "Description": "Become a digital property artisan with EsproNFT's Domain Crafters collection. Explore and collect NFTs that showcase the artistry of virtual property ownership. Craft your domain now!"
      },
      {
        "ID": 65,
        "Title": "CyberPropertyX NFTs-EsproNFT's Digital Real Estate Revolution",
        "Description": "Revolutionize your digital presence with EsproNFT's CyberPropertyX collection. Explore and collect NFTs that redefine the concept of virtual property ownership. Secure your cyber domain now"
      },
      {
        "ID": 66,
        "Title": "NFT Wardrobe-EsproNFT's Virtual Fashion Collection",
        "Description": "Elevate your digital wardrobe with EsproNFT's NFT Wardrobe collection. Explore and collect NFTs that redefine virtual fashion. Upgrade your style in the digital realm now"
      },
      {
        "ID": 67,
        "Title": "Fantasy Weapon Forge NFTs-EsproNFT's Digital Armory",
        "Description": "Craft your digital armory with EsproNFT's Fantasy Weapon Forge collection. Explore and collect NFTs that bring fantasy weapons to life in the digital realm. Arm up now!"
      },
      {
        "ID": 69,
        "Title": "VR Real Estate Gems NFTs- EsproNFT's Immersive Holdings",
        "Description": "Dive into immersive holdings with EsproNFT's VR Real Estate Gems collection. Explore and collect NFTs that redefine the concept of virtual property ownership. Secure your gems now"
      },
      {
        "ID": 70,
        "Title": "Cosmic Craftables NFTs- EsproNFT's Celestial Creations",
        "Description": "Explore celestial creations with EsproNFT's Cosmic Craftables collection. Collect NFTs that bring cosmic wonders to life in the digital realm. Craft your cosmic collection now"
      },
      {
        "ID": 71,
        "Title": "Avatar Ensemble NFTs- EsproNFT's Digital Styling",
        "Description": "Style your digital presence with EsproNFT's Avatar Ensemble collection. Explore and collect NFTs that redefine the art of digital fashion. Elevate your avatar's style now"
      },
      {
        "ID": 72,
        "Title": "Cyber Pets Craze NFTs- EsproNFT's Virtual Pet Mania",
        "Description": "Join the virtual pet mania with EsproNFT's Cyber Pets Craze collection. Explore and collect NFTs that bring digital pets to life in the virtual realm. Adopt yours now"
      },
      {
        "ID": 73,
        "Title": "Crypto Passport NFTs- EsproNFT's Digital Identity Journey",
        "Description": "Embark on a digital identity journey with EsproNFT's Crypto Passport collection. Explore and collect NFTs that redefine the concept of virtual passports. Begin your journey now"
      },
      {
        "ID": 74,
        "Title": "Avatar Ancestry NFTs- EsproNFT's Digital Lineage",
        "Description": "Trace your digital lineage with EsproNFT's Avatar Ancestry collection. Explore and collect NFTs that redefine the concept of virtual heritage. Discover your avatar's roots now"
      },
      {
        "ID": 75,
        "Title": "NFT Identity Canvas-EsproNFT's Digital Self-Expression",
        "Description": "Express your digital self with EsproNFT's NFT Identity Canvas collection. Explore and collect NFTs that redefine the art of virtual self-expression. Create your canvas now!"
      },
      {
        "ID": 76,
        "Title": "Virtual DNA Profile NFTs- EsproNFT's Unique Digital Code",
        "Description": "Define your unique digital code with EsproNFT's Virtual DNA Profile collection. Explore and collect NFTs that embody the essence of your virtual identity. Discover now"
      },
      {
        "ID": 77,
        "Title": "eSignature Seals NFTs-EsproNFT's Digital Authentication",
        "Description": "Authenticate your digital presence with EsproNFT's eSignature Seals collection. Explore and collect NFTs that redefine the concept of digital signatures. Secure your authenticity now!"
      },
      {
        "ID": 78,
        "Title": "Explore CyberCitizenship NFT-A Digital Realm of Responsible Online Participation",
        "Description": "Immerse yourself in the CyberCitizenship NFT, a groundbreaking digital experience promoting responsible online behavior. Discover unique virtual assets and join the movement for a safer internet."
      },
      {
        "ID": 80,
        "Title": "Digital Persona Portraits-Unveil Your Virtual Identity in NFT Art",
        "Description": "Discover the allure of Digital Persona Portraits at EsproNFT. Elevate your virtual identity with unique NFT art that captures the essence of your digital self. Explore now"
      },
      {
        "ID": 81,
        "Title": "Blockchain ID Shield- Fortify Your Identity with Cutting-Edge NFT Security",
        "Description": "Enhance your online security with Blockchain ID Shield from EsproNFT. Explore innovative NFT solutions to safeguard your digital identity. Strengthen your defenses today"
      },
      {
        "ID": 82,
        "Title": "Biometric Signature Art- Your Unique Identity in NFT Masterpieces",
        "Description": "Immerse yourself in the world of Biometric Signature Art at EsproNFT. Unveil the beauty of personalized identity through captivating NFT masterpieces. Explore now"
      },
      {
        "ID": 83,
        "Title": "NFT Citizenship-Embrace Digital Membership with Virtual Assets",
        "Description": "Join the realm of NFT Citizenship at EsproNFT. Acquire exclusive virtual assets, forging your identity in the digital frontier. Explore the future of online ownership"
      },
      {
        "ID": 84,
        "Title": "CryptoVisage Cards- Unveil Your Unique Identity in NFT Portraits",
        "Description": "Discover CryptoVisage Cards at EsproNFT – where art meets identity. Own exclusive NFT portraits capturing the essence of your digital persona. Explore the gallery now!"
      },
      {
        "ID": 85,
        "Title": "Digital Furniture Fix- Transform Spaces with NFT Virtual Décor",
        "Description": "Revolutionize your space with Digital Furniture Fix from EsproNFT. Elevate your surroundings through unique NFT virtual décor solutions. Explore the future of interior design!"
      },
      {
        "ID": 86,
        "Title": "Metaverse Mementos-Capture Memories in Unique NFT Artifacts",
        "Description": "Immerse yourself in Metaverse Mementos at EsproNFT. Preserve memories with exclusive NFT artifacts, blending technology and sentiment. Explore the future of keepsakes"
      },
      {
        "ID": 87,
        "Title": "Magic Mystery Boxes-Unveil Enchanting Surprises in NFT Collections",
        "Description": "Experience the allure of Magic Mystery Boxes at EsproNFT. Open the door to enchantment with exclusive NFT collections, each box holding a magical surprise. Explore now"
      },
      {
        "ID": 88,
        "Title": "Crypto Comics Capsules-Immerse in NFT Adventures and Collectibles",
        "Description": "Embark on NFT adventures with Crypto Comics Capsules at EsproNFT. Collect unique digital comics encapsulated in a world of blockchain creativity. Dive into the excitement now"
      },
      {
        "ID": 89,
        "Title": "Epic Game Gear Vault- Elevate Your Gaming Experience with NFTs",
        "Description": "Unleash the power of Epic Game Gear Vault at EsproNFT. Elevate your gaming experience with exclusive NFTs, unlocking a world of digital excitement. Level up now"
      },
      {
        "ID": 90,
        "Title": "NFT Concert Craze- Digital Tickets to Exclusive Virtual Performances",
        "Description": "Immerse yourself in the NFT Concert Craze at EsproNFT. Secure digital tickets for exclusive virtual performances and join the wave of immersive online entertainment."
      },
      {
        "ID": 91,
        "Title": "Virtual Cuisine Delight- Explore NFT Culinary Experiences",
        "Description": "Indulge in Virtual Cuisine Delight at EsproNFT. Experience the fusion of art and taste with exclusive NFT culinary adventures. Elevate your dining experience today"
      },
      {
        "ID": 92,
        "Title": "Pixel Pets Parade-Collect and Care for NFT Virtual Companions",
        "Description": "Join the Pixel Pets Parade at EsproNFT. Discover, collect, and care for adorable NFT virtual companions. Experience the joy of digital pet ownership. Explore now!"
      },
      {
        "ID": 93,
        "Title": "Cyber Fashion Fusion- Elevate Style with Exclusive NFT Trends",
        "Description": "Step into the future of fashion with Cyber Fashion Fusion at EsproNFT. Explore and own unique NFT trends, merging style and digital innovation. Upgrade your wardrobe now!"
      },
      {
        "ID": 94,
        "Title": "Blockchain Philanthropy-Transforming Charity with NFT Innovations",
        "Description": "Explore the realm of Blockchain Philanthropy at EsproNFT. Experience the power of NFT innovations in reshaping and enhancing charitable giving. Make a difference today!"
      },
      {
        "ID": 95,
        "Title": "EtherWeb Estates-Own a Piece of the Digital Landscape",
        "Description": "Discover EtherWeb Estates at EsproNFT. Secure your stake in the virtual realm with exclusive NFTs, reshaping the landscape of digital ownership. Start exploring now"
      },
      {
        "ID": 96,
        "Title": "Virtual NFT Domains-Claim Your Digital Presence with Exclusive",
        "Description": "Establish your digital presence with Virtual NFT Domains from EsproNFT. Own exclusive NFTs and redefine your place in the virtual landscape. Explore now"
      },
      {
        "ID": 97,
        "Title": "CryptoURLs- Elevate Your Online Identity with Exclusive NFT Links",
        "Description": "Explore CryptoURLs at EsproNFT and redefine your online identity with exclusive NFT links. Secure your digital presence and stand out in the virtual world"
      },
      {
        "ID": 98,
        "Title": "NFT Web Registry- Secure Your Virtual Space with Exclusive NFTs",
        "Description": "Explore NFT Web Registry at EsproNFT. Claim your virtual space with exclusive NFTs, shaping the future of online identity. Own your piece of the digital world"
      },
      {
        "ID": 99,
        "Title": "Blockchain Biosphere-Explore NFT Ecosystems and Digital Ecology",
        "Description": "Dive into the Blockchain Biosphere at EsproNFT. Explore unique NFT ecosystems, shaping the digital ecology of tomorrow. Immerse yourself in the virtual world now!"
      },
      {
        "ID": 100,
        "Title": "Ethereal Domains-Elevate Your Digital Presence with Exclusive NFTs",
        "Description": "ransform your digital presence with Ethereal Domains at EsproNFT. Own exclusive NFTs and redefine your space in the virtual realm. Explore the ethereal now"
      },
      {
        "ID": 101,
        "Title": "Web Asset Wizards-Craft Your Digital Realm with Exclusive NFTs",
        "Description": "Unleash the magic of Web Asset Wizards at EsproNFT. Own exclusive NFTs and craft your digital realm with unparalleled creativity. Dive into the wizardry now!"
      },
      {
        "ID": 102,
        "Title": "Pixelland Titles-Secure Your Virtual Kingdom with NFT Royalty",
        "Description": "Claim your virtual kingdom with Pixelland Titles at EsproNFT. Acquire exclusive NFTs, establishing digital royalty in the vibrant realm of Pixelland. Rule your pixels"
      },
      {
        "ID": 103,
        "Title": "NFT Net Nexus- Connect and Navigate the Digital Frontiers",
        "Description": "Explore NFT Net Nexus at EsproNFT. Connect and navigate the vast digital frontiers with exclusive NFTs, forging pathways in the evolving virtual landscape"
      },
      {
        "ID": 104,
        "Title": "EcoNFTs- Foster Sustainability Through Exclusive Digital Assets",
        "Description": "Champion sustainability with EcoNFTs at EsproNFT. Own exclusive digital assets contributing to eco-friendly initiatives. Embrace a greener digital future today"
      },
      {
        "ID": 105,
        "Title": "Blockchain for Impact- Transforming Futures with NFT Innovations",
        "Description": "Discover the power of Blockchain for Impact at EsproNFT. Explore NFT innovations shaping a positive digital future. Join the movement for transformative impact!"
      },
      {
        "ID": 106,
        "Title": "Artistic Altruism-NFTs for a Purposeful Creative Impact",
        "Description": "Explore the world of Artistic Altruism at EsproNFT. Acquire NFTs that contribute to a purposeful creative impact, combining artistry with philanthropy. Join the movement!"
      },
      {
        "ID": 107,
        "Title": "Ethical Editions- NFTs Redefining Digital Collectibles",
        "Description": "Discover Ethical Editions at EsproNFT. Explore exclusive NFTs that redefine digital collectibles with ethical principles. Join the evolution of conscious ownership now!"
      },
      {
        "ID": 108,
        "Title": "Artistic Activism-NFTs Driving Social Change Through Art",
        "Description": "Explore Artistic Activism at EsproNFT. Acquire NFTs that drive social change through powerful artistic expressions. Join the movement for a better world through art!"
      },
      {
        "ID": 109,
        "Title": "Tech4Good Tokens- Empowering Positive Change Through NFTs",
        "Description": "Empower positive change with Tech4Good Tokens at EsproNFT. Discover NFTs driving technology for good and contribute to meaningful initiatives. Join the movement now!"
      },
      {
        "ID": 110,
        "Title": "Heartfelt Holdings-NFTs Infused with Emotion and Purpose",
        "Description": "Explore Heartfelt Holdings at EsproNFT. Acquire NFTs infused with emotion and purpose, making each holding a meaningful expression in the digital realm. Discover now!"
      },
      {
        "ID": 111,
        "Title": "NFTs for Social Good- Empower Change with Purposeful Digital Art",
        "Description": "Unlock the potential of NFTs for Social Good at EsproNFT. Contribute to positive change with purposeful digital art. Join the movement for a better world through NFTs!"
      }
    ],
    PRODUCT_DESCRIPTION:
      {
        1: "Unleash your survival instincts and immerse yourself in the heart-pounding horror of a virtual zombie apocalypse with this one-of-a-kind NFT, \"VR Zombie\".",
        2: "A one-of-a-kind digital collectible from the iconic Bored Ape Yacht Club (BAYC) NFT collection. This unique ape artwork features a captivating blend of attributes, showcasing a distinctive personality within the BAYC universe.",
        3: "Unleash your inner joy with the infectiously cheerful Happy Ape NFT! This vibrant collectible captures the essence of pure happiness, radiating positivity and good vibes.",
        5: "Own a piece of art that celebrates the enchanting spirit of monkeys and the captivating diversity of the animal kingdom",
        6: "Immerse yourself in the vibrant and whimsical world of \"The Simpson Art\" NFT collection. Each digital masterpiece captures iconic moments from the beloved animated series, bringing to life the humor, satire, and cultural commentary that has made \"The Simpsons\" a timeless classic. Whether it's Homer's donut indulgence or Bart's mischievous adventures, these NFTs are a celebration of the show's unique charm and enduring legacy. ",
        7: "Step into a digital fairytale with \"Digital Rose Dream Gown,\" a luminous NFT gown woven from cascading code-petals. This pixelated masterpiece shimmers with iridescent dreams, blurring the lines between couture and the metaverse.",
        8: "Holographic Jewelry NFTs dance with light, their facets shimmering in a kaleidoscope of hues. Own the future of wearable wonder, where pixels become gems and every glance ignites the imagination.",
        9: "Ditch the dusty canvas, the future of female empowerment unfurls in pixels. World of Women NFTs bring a kaleidoscope of diverse personalities to life, each a digital heroine blazing a trail through the metaverse. From tech titans to artists, athletes to entrepreneurs, these women defy stereotypes and rewrite the narrative. Own a piece of this inclusive revolution, where every brushstroke celebrates the infinite possibilities of womanhood.",
        10: "Moonbirds: beyond pixelated owls, they're your key to an exclusive NFT club, PROOF Collective. Access hidden forums, events, and mint limited-edition NFTs. The longer you hold, the deeper you nest, unlocking voting power, merch, and unique experiences. Soar with these feathered friends, your ticket to a vibrant corner of the web3 world.",
        11: "Owning a Pudgy Penguin isn't just about collecting cute art - it's about joining \"The Huddle,\" a thriving community of penguin enthusiasts. Holders gain exclusive access to events, merch, games, and even the chance to shape the future of the Pudgy Penguin universe.",
        12: "Enter a dazzling, digital realm where adorable Axies reign supreme. These fantastical creatures, each boasting unique looks and powers, await you in a world teeming with adventure. Assemble your dream team, breed the next generation, and conquer thrilling battles across sprawling landscapes.",
        13: "They were pixel pioneers, digital trailblazers, the OGs of NFTs. Now, they're ready to break free from the blockchain and infiltrate your schema. Behold, the legendary CryptoPunks!",
        14: "In this metropolis of limitless possibilities, you're not just a visitor—you're a co-creator. Own a piece of the city, shape its evolving landscape, and forge your own destiny within its boundless realms.",
        15: "Forget towering skyscrapers and bustling avenues - envision a metropolis woven from code, where citizens co-govern and communities thrive in a digital utopia. Welcome to Decentralized City, an NFT project paving the way for a reimagined urban landscape.",
        16: "Welcome to the Cryptoverse, an ever-expanding realm where the boundaries of imagination and innovation blur. Here, NFTs aren't just collectibles—they're the keys that unlock a universe of interconnected experiences, communities, and possibilities.",
        17: "In the heart of the metaverse, a realm of boundless potential beckons. Welcome to Cyberia, where the lines between technology and imagination blur, and every pixel pulses with untold possibilities.",
        18: "From the primordial chaos of the digital realm, a collection emerges, steeped in boundless potential and limitless creativity. Welcome to Genesis, where the first sparks of innovation ignite a universe of possibilities.",
        19: "The Gameverse isn't just a collection of NFTs - it's a portal to a sprawling, vibrant realm where digital dimensions collide. Imagine a universe where iconic gaming worlds intertwine, legendary characters break free from their screens, and players become the heroes of their own epic adventures.",
        20: "Imagine a world where imagination reigns supreme, and every pixel pulses with possibility. Welcome to the Digital Playground, an NFT collection that unlocks a vibrant realm of endless exploration, connection, and creative expression.",
        21: "Transcend the boundaries of traditional gaming and enter a universe of endless possibilities with Limitless Gaming NFTs. This pioneering collection unlocks a gateway to boundless exploration, exhilarating challenges, and a vibrant community of fellow adventurers.",
        22: "Buckle up, space rangers, and prepare to warp speed into the GameFi Galaxy! This revolutionary NFT collection isn't just about conquering digital landscapes; it's about unlocking a universe of thrilling gameplay, earning potential, and an interstellar community that knows how to have fun.",
        23: "Forget flat screens and clunky headsets - the Virtual Reality Realm is where digital dreams become hyper-real explorations. This NFT collection isn't just about owning art; it's about wielding your senses as passports to boundless, immersive adventures.",
        24: "Ready to experience a world where gaming meets the boundless potential of NFTs? NFTPlays is here to unlock a new era of play, where gamers hold true ownership, immersive experiences captivate, and communities thrive in a digital landscape crafted for pure excitement.",
        25: "Ready to experience sports like never before? Metaverse League Sports is breaking boundaries and redefining the game, inviting you to step onto digital fields where the action is electrifying, the community is unstoppable, and the thrill of victory has never felt more real.",
        26: "In the heart of the pulsating metaverse, where pixels pulse with athletic spirit, a new breed of champions emerges. Forget gravity-bound limitations and meet the Metaverse Athletes, digital dynamos poised to redefine the very meaning of sports.",
        27: "Get ready to experience a new era of digital sport where ownership, community, and thrilling gameplay collide. Crypto Ball isn't just a collection of pixels; it's a pulsating, player-powered universe where every bounce holds the potential for epic rewards and unforgettable experiences.",
        28: "Forget pristine whites and willow, the future of cricket has arrived in a blaze of neon and pixels. Cyber Cricket isn't just an NFT collection; it's a portal to a hyper-charged digital realm where sixes soar through gravity-defying arenas, boundaries redefine physics, and every delivery sparks electrifying excitement.",
        30: "Prepare to step into a realm where fashion transcends boundaries and creativity knows no limits. The BitBoutique Emporium isn't just an NFT collection; it's a vibrant marketplace where cutting-edge digital fashion meets a community of style enthusiasts, artists, and collectors ready to redefine what it means to express yourself in the metaverse.",
        31: "Imagine a haven nestled amongst celestial clouds, bathed in ethereal light and whispered by the winds of possibility. Welcome to Cloud Castle Haven, an NFT collection that unlocks your passport to a breathtaking realm of serene skyscapes, enchanting communities, and boundless creativity.",
        32: "Forget stale breadsticks and lukewarm coffee - the Virtual Food Festival is here to ignite your digital taste buds with an explosion of global flavors and immersive culinary experiences. This innovative NFT collection isn't just about owning artwork; it's about unlocking a passport to a vibrant virtual world where taste transcends limitations and every bite unfolds into an unexpected adventure.",
        33: "Forget cramped cellars and stuffy sommeliers - Virtual Wine Tasting is here to revolutionize your oenophilic adventures. This innovative NFT collection isn't just about digital art; it's your passport to a vibrant, pixelated vineyard where vines twist across the screen, grapes shimmer with bioluminescent hues, and every sip unfolds into an immersive learning experience.",
        34: "Forget crowded gyms and monotonous routines - VirtualFit Fest is here to revolutionize your workout in a world where pixels pulse with every push-up and high-fives fly across the metaverse. This groundbreaking NFT collection isn't just about digital art; it's your passport to a vibrant, gamified fitness playground where the limits are only as real as your screen and the rewards are more than just bragging rights.",
        35: "Imagine a world where fashion transcends reality and style blooms in the pixels. The Boho-Chic Maxi Dress NFT isn't just a digital garment; it's a portal to a vibrant wardrobe where sun-kissed fabrics flow with every step, earthy tones harmonize with your spirit, and every thread whispers tales of freedom and wanderlust.",
        36: "Venture into a realm of fantasy and fear with the \"Scary Baby Hobbit Art\" NFT collection. This unique series combines the innocence of childhood with the enchanting world of hobbits, creating a juxtaposition that is both eerie and captivating. Each digital artwork portrays unsettling yet intriguing scenes of baby hobbits in fantastical landscapes, evoking a sense of wonder and unease. Fans of fantasy, horror, and art enthusiasts alike will appreciate the skillful craftsmanship and storytelling embedded in these NFTs. ",
        37: "In the realm where life redefines itself, the Skeleton Flower Pot NFT isn't just a container; it's a canvas for the uncanny and an invitation to embrace the beauty of the macabre.",
        38: "A collection of NFT crowns and jewels, with each design referencing a different global cause or charity supported by Princess Diana. This could be a powerful way to raise awareness and funds for important issues while paying homage to her legacy.",
        39: "Let the Earth adorn you with its exquisite whispers. The Nature-Inspired Leaf Earrings NFT isn't just a digital accessory; it's a portal to a world where emerald veins pulsate with life, sunlit dewdrops shimmer on pixelated petals, and every wear whispers ancient tales of the forest.",
        40: "Forget predictable patterns and timid hues. The Modern Muse Jumpsuit NFT isn't just a digital garment; it's a manifesto, a celebration of bold lines, electrifying colors, and the unapologetic spirit of urban chic.",
        41: "Forget demure whispers and muted tones. The Crimson Cascade Dress NFT isn't just a digital garment; it's a siren song, an invitation to unleash your inner fire and dance in a sea of vibrant scarlet.",
        42: "Forget passive admiration and fleeting applause. \"Art for Change\" isn't just an NFT; it's a battle cry, a brushstroke across the canvas of social justice, and an invitation to use your digital pixels to spark real-world revolutions.",
        43: "In a world of digital frontiers, where innovation sprints ahead, a new kind of collective emerges—one that blends the power of technology with the warmth of human connection. This is the Cryptocare Collective NFT, a beacon of empathy and support in the heart of the metaverse.",
        44: "In a world where the internet is a universe of its own, imagine owning a piece of its very foundation. Introducing CryptoTLDs NFT, a revolutionary concept that empowers you to stake your claim on the digital frontier and redefine the boundaries of online identity.",
        45: "In a world where digital fragments hold infinite possibilities, where imagination paints the canvas of the metaverse, emerge the CryptoCollectiBits NFT. More than mere pixels, they're a vibrant symphony of creativity, community, and endless potential, inviting you to curate a collection as unique as your digital fingerprint.",
        46: "In a realm where adventure knows no boundaries and imagination fuels every quest, the VirtualTreasureHunt NFT beckons you to embark on a journey unlike any other. Prepare to navigate uncharted digital landscapes, decipher cryptic clues, and unlock treasures hidden within the very fabric of the metaverse.",
        47: "In the ever-expanding metaverse, where virtual identities hold immense power, trust and authenticity become paramount. Introducing AvatarAuthenticity NFT, a groundbreaking solution that safeguards the integrity of your digital self, ensuring that your avatar is a true reflection of who you are and what you stand for.",
        48: "In a world where imagination is the architect and virtual spaces are limitless canvases, VirtualDomainsVR NFT unlocks a realm where you reign as the master of your own digital domain.",
        49: "In a world where our virtual identities hold immense power, DigitalPersonaKeys NFT reimagines the way we express ourselves, connect with others, and navigate the ever-evolving landscapes of the metaverse.",
        50: "Forget fleeting trends and skyrocketing prices. Impactful Tokens aren't just digital assets; they're catalysts for positive change, igniting a revolution where the ripples of your investment extend far beyond the metaverse.",
        51: "In a realm where virtual landscapes hold boundless potential, DomainDynasty NFT invites you to forge a legacy of innovation and establish your reign over a kingdom of limitless possibilities.",
        52: "In a world where security and individuality intersect, BiometricBadge NFTs usher in a paradigm shift in how we assert our digital presence. Embracing the uniqueness of our biology, they offer an unprecedented fusion of authenticity, security, and self-expression within the virtual realm.",
        53: "In a world where security and individuality intersect, BiometricBadge NFTs usher in a paradigm shift in how we assert our digital presence. Embracing the uniqueness of our biology, they offer an unprecedented fusion of authenticity, security, and self-expression within the virtual realm.",
        54: "In the ever-expanding metaverse, where ownership and provenance are paramount, BitBlockTitles NFTs offer a groundbreaking way to assert your claim and immortalize your legacy within the digital landscape.",
        55: "In a world where your online presence holds immense power, securing your virtual identity becomes paramount. VirtualIdentityVault NFTs emerge as the ultimate guardians of your digital legacy, offering unparalleled protection, control, and self-sovereignty within the metaverse.",
        56: "In a world where pixelated possibilities are limitless, PixelPowerUps NFTs infuse your digital journey with extraordinary abilities and electrifying experiences, redefining what it means to thrive in the metaverse.",
        57: "In the vibrant tapestry of the metaverse, every individual is a masterpiece in the making. IdentityMosaic NFTs empower you to craft a digital persona that's as unique and multifaceted as you are, celebrating the limitless possibilities of self-expression in a virtual world.",
        58: "NFTs for Good aren't just digital trinkets; they're tools for real-world change. Imagine owning an NFT that empowers marginalized communities, protects endangered species, or fuels groundbreaking environmental initiatives. Every click becomes a ripple of positive impact, transforming the metaverse into a canvas for collective action.",
        59: "In the boundless expanse of the metaverse, where digital identities hold immense power, DigiNameVault NFTs emerge as beacons of authenticity, security, and self-expression. They offer a revolutionary way to safeguard your virtual presence, embody your unique essence, and shape your legacy within the ever-evolving digital landscape.",
        60: "In a world where imagination holds the pixels, PixelDomains NFT invites you to carve your own vibrant corner of the metaverse. Each NFT represents a unique, customizable canvas where creativity knows no bounds, and every pixel pulsates with endless possibilities.",
        61: "In the uncharted territories of the metaverse, where virtual domains hold boundless potential, NFTDomainQuest beckons you to embark on an exhilarating adventure. Each NFT represents a unique map, guiding you through captivating challenges and rewarding you with the chance to claim your own piece of the digital frontier.",
        62: "In a realm where virtual landscapes transcend boundaries, DigitalDomein NFT invites you to stake your claim and establish a vibrant sanctuary of limitless possibilities. Each NFT represents a unique plot of virtual land, a canvas for boundless creativity, immersive experiences, and boundless self-expression.",
        63: "In the boundless expanse of virtual worlds, where identities are fluid and possibilities endless, MetaNameRegistry NFTs emerge as the guardians of your digital legacy. They offer a groundbreaking way to secure your chosen name across the metaverse, ensuring authenticity, self-expression, and a seamless experience across realms.",
        64: "In the boundless realm of the metaverse, where imagination meets innovation, DomainCrafters NFTs empower you to become an architect of virtual worlds. Each NFT holds the potential to unlock a canvas of limitless possibilities, where your creativity transforms pixels into vibrant landscapes, immersive experiences, and thriving communities.",
        65: "In the burgeoning metaverse, where virtual landscapes hold boundless potential, CyberPropertyX NFTs unlock a revolutionary way to own, develop, and monetize your slice of the digital world. Each NFT represents a unique plot of virtual land, empowering you to shape its evolution and reap the rewards of your digital investment.",
        66: "In a world where pixels are the new threads, NFTWardrobe unlocks a revolutionary way to express your unique style and individuality throughout the metaverse. Each NFT represents a one-of-a-kind digital garment or accessory, empowering you to curate a virtual wardrobe that's as bold, dynamic, and limitless as your imagination.",
        67: "In realms where imagination fuels epic battles and heroes forge their destinies, FantasyWeaponForge NFTs unlock an arsenal of exquisitely crafted digital weaponry. Each NFT embodies a unique fusion of artistry and ingenuity, igniting the spark of adventure within every collector and metaverse explorer.",
        69: "In the burgeoning metaverse, where boundless possibilities stretch beyond the horizon, VRRealEstateGems NFTs present a coveted collection of exquisite virtual properties. Each NFT is a gateway to a meticulously crafted domain, poised to become your personal sanctuary, thriving business hub, or awe-inspiring immersive experience.",
        70: "In a universe where imagination is the architect, CosmicCraftables NFTs unlock a celestial workshop where everyday objects transcend the ordinary. Each NFT represents a unique, craftable item imbued with stardust and boundless potential, empowering you to shape the cosmos with your creativity.",
        71: "In a world where virtual identities spark endless possibilities, AvatarEnsemble NFTs empower you to curate a wardrobe that transcends boundaries and ignites self-expression. Each NFT represents a unique, meticulously crafted digital garment or accessory, inviting you to assemble a collection that reflects your individuality and shapes your journey through the metaverse.",
        72: "In a world where virtual companions spark joy and endless adventures, CyberPetsCraze NFTs invite you to nurture unique digital creatures that are as playful, diverse, and captivating as your imagination. Each NFT represents a one-of-a-kind pet brimming with personality, ready to embark on unforgettable journeys alongside you in the metaverse.",
        73: "In the burgeoning metaverse, where virtual realms beckon with boundless opportunities, CryptoPassport NFTs emerge as your gateway to seamless exploration and unparalleled experiences. Each NFT serves as your unique digital identity, empowering you to navigate diverse virtual worlds with ease, forge meaningful connections, and unlock exclusive benefits along the way.",
        74: "In the ever-evolving metaverse, where virtual identities hold boundless stories, AvatarAncestry NFTs invite you to trace the lineage of your avatar and uncover a rich tapestry of heritage, evolution, and shared experiences. Each NFT represents a unique genealogical record, revealing the interconnected journeys that have shaped your digital presence.",
        75: "In the boundless realm of virtual worlds, where avatars become extensions of our personalities, NFTIdentityCanvas NFTs empower you to craft a digital identity as unique and vibrant as your own. Each NFT acts as a boundless canvas, inviting you to express your passions, values, and individuality through a tapestry of customizable elements.",
        76: "In the burgeoning metaverse, where virtual identities hold boundless potential, VirtualDNAProfile NFTs emerge as a revolutionary way to capture and express the unique essence of your avatar. Each NFT encapsulates a dynamic profile that blends your preferences, experiences, and aspirations, shaping your journey through the virtual world in profound ways.",
        77: "In a world where agreements and transactions increasingly occur in the digital realm, eSignatureSeals NFTs emerge as a revolutionary solution for establishing authenticity, trust, and irrefutable proof of ownership. Each NFT acts as a unique, tamper-proof digital signature, empowering individuals and organizations to safeguard their intellectual property and forge secure digital relationships.",
        78: "In the burgeoning metaverse, where virtual worlds collide and endless possibilities beckon, CyberCitizenship NFTs unlock a revolutionary way to claim your stake and shape the future of this digital frontier. Each NFT represents not just ownership, but active participation in a thriving virtual society, granting you the rights, responsibilities, and privileges of a true citizen.",
        80: "Imagine a world where your avatar isn't just a pre-designed shell, but a canvas for your unique essence. That's the power of DigitalPersonaPortraits, an NFT collection that empowers you to create a one-of-a-kind digital portrait that captures your soul in pixels.",
        81: "In the ever-evolving digital landscape, protecting your identity is paramount. That's where BlockchainIDShield NFTs step in, offering an innovative solution to safeguard your online presence with the power of blockchain technology.",
        82: "In a world where art pushes boundaries, BiometricSignatureArt NFTs redefine the canvas. Each NFT captures the unique rhythm of your heartbeat, transforming it into a mesmerizing visual symphony that pulsates with life and tells the story of your very being.",
        83: "The metaverse is no longer a distant dream; it's a burgeoning nation waiting to be shaped. NFTCitizenship NFTs empower you to become a founding member of this virtual society, granting you the rights, responsibilities, and privileges of a true citizen.",
        84: "In the metaverse, your avatar is your face to the world. But what if that face could be more than just pixels and polygons? CryptoVisageCards NFTs unlock the potential of dynamic, evolving avatars that reflect your personality, achievements, and even real-time emotions.",
        85: "Forget the limitations of physical décor. DigitalFurnitureFix NFTs unlock a world of limitless creativity, letting you furnish your virtual spaces with stunning, customizable furniture that pushes the boundaries of design.",
        86: "The metaverse is a canvas for countless memories, from epic adventures to hilarious mishaps and heartwarming moments of connection. MetaverseMementos NFTs let you capture these precious experiences and preserve them forever, transforming fleeting moments into treasured digital keepsakes.",
        87: "The thrill of the unknown, the anticipation of a surprise, the joy of discovery - these are the emotions that MagicMysteryBoxes NFTs evoke. Each box is a portal to a world of possibilities, containing unique digital items, experiences, and even in-game advantages that will enhance your journey through the metaverse.",
        88: "Dive into interactive, dynamic comic book experiences with CryptoComicsCapsules. These NFTs aren't just static images; they're portals to unfolding narratives where you choose the path, influence the story, and own a piece of the creative process. Imagine flipping through a comic book where the panels come alive, characters react to your choices, and hidden secrets are revealed based on your decisions. With CryptoComicsCapsules, the lines between reader and creator blur, making you an active participant in the vibrant world of comics.",
        89: "Gear up for epic adventures with the EpicGameGearVault, an NFT collection that unlocks legendary in-game items, exclusive weapons, and powerful armor for your favorite metaverse games. Imagine wielding a mythical sword that crackles with energy, riding a majestic griffin across fantastical landscapes, or donning armor forged from the scales of a celestial dragon. EpicGameGearVault NFTs elevate your gameplay, granting you an edge in competitive arenas and letting you stand out from the crowd with awe-inspiring visual flair.",
        90: "Experience the future of live music with NFTConcertCraze, a revolutionary platform that transforms concerts into interactive, immersive NFT experiences. These NFTs aren't just tickets; they're backstage passes to exclusive content, personalized interactions with artists, and ownership of limited-edition digital collectibles from the show. Imagine front-row seats with virtual reality overlays that let you dance on stage with your favorite band, backstage tours with the artists themselves, and owning a unique NFT recording of that unforgettable concert moment. NFTConcertCraze brings the energy and excitement of live music to the metaverse, blurring the lines between audience and performer and creating memories that transcend the physical world.",
        91: "Dive into a feast for the senses with VirtualCuisineDelight NFTs. These delectable digital tokens aren't just pixelated snacks; they're portals to immersive culinary experiences that tantalize your taste buds and transport you to vibrant virtual restaurants. Imagine whipping up sizzling dishes from around the world with interactive cookbooks, dining in Michelin-starred spaces with stunning 3D environments, and even owning NFTs of exclusive recipes you can recreate in the real world.",
        92: "Unleash a whirlwind of adorable chaos with PixelPetsParade NFTs. These charming little critters aren't just static pixel art; they're your interactive, evolving companions in the metaverse. Imagine raising, nurturing, and playing with your own unique pixel pet, watching them grow, learn tricks, and even participate in competitive races and obstacle courses with other pixel companions. PixelPetsParade lets you experience the joy of pet ownership in a captivating digital format, building lasting bonds with your virtual furry friends.",
        93: "Embrace a kaleidoscope of style with CyberFashionFusion NFTs. These aren't just digital clothes; they're your canvas for self-expression, defying the boundaries of the physical world and pushing the frontiers of futuristic fashion. Imagine dressing your avatar in dazzling neon garments that shift and react to your emotions, sporting cybernetic wings that grant you in-game flight, or customizing your look with interactive accessories that personalize your digital persona.",
        94: "Witness the future of giving with Blockchain Philanthropy NFTs. These unique tokens transcend traditional charity, offering transparent, secure, and impactful ways to support causes you care about in the metaverse. Imagine owning an NFT that funds a virtual school for underprivileged children or contributes to building a sustainable, green energy source within the digital world.",
        95: "Craft your digital dynasty with EtherWebEstates. These NFTs aren't just virtual plots of land; they're your canvas to build empires, cultivate communities, and leave your mark on the ever-evolving metaverse. Imagine owning a bustling marketplace teeming with vibrant shops and interactive experiences, establishing a flourishing virtual city where art, business, and leisure intertwine, or even constructing a futuristic fortress overlooking breathtaking digital landscapes.",
        96: "Forge your digital identity with VirtualNFTDomains. These exclusive NFTs aren't just web addresses; they're your passport to personalized access, custom experiences, and a distinct presence within the metaverse. Imagine owning a domain that reflects your passions, connects you to like-minded individuals, and grants you exclusive benefits within specific virtual communities.",
        97: "Imagine a web where addresses are unique, dynamic, and even collectible. That's the power of CryptoURLs, innovative NFTs that redefine how we navigate the digital landscape. Ditch the impersonal strings of characters and claim your own personalized CryptoURL, a visually mesmerizing portal that reflects your online identity and grants exclusive access to customized experiences. Think shimmering constellations guiding you to your favorite websites, pulsating neon signs leading to vibrant online communities,",
        98: "In the ever-evolving metaverse, authenticity and ownership are paramount. Enter the NFTWebRegistry, a revolutionary platform that verifies and secures your digital assets, ensuring that your precious NFTs are protected and easily discoverable. Think of it as a gleaming vault of digital treasures, meticulously catalogued and protected by cutting-edge blockchain technology. Each NFT within the registry is assigned a unique, verifiable identifier, allowing collectors, creators, and enthusiasts to navigate the metaverse with confidence and trust.",
        99: "tep into a thriving digital ecosystem where nature takes on a revolutionary form. BlockchainBiosphere NFTs bring the wonders of the natural world to the metaverse, fostering a sustainable and diverse digital environment. Imagine owning a majestic, pixelated redwood soaring in a virtual forest, nurturing unique digital creatures that evolve and interact with their surroundings, or cultivating vibrant coral reefs that cleanse the data streams of the metaverse.",
        100: "Imagine owning a piece of the digital heavens, a sprawling expanse bathed in stardust and celestial hues. EtherealDomains NFTs aren't just virtual plots of land; they're celestial canvases where you can build soaring sky cities, cultivate luminous gardens of alien flora, or even host awe-inspiring galactic gatherings under shimmering nebulas. Think floating islands cradled by cosmic currents, ethereal towers adorned with twinkling constellations, and bustling marketplaces illuminated by the soft glow of distant stars.",
        101: "Unleash your digital alchemy with WebAssetWizards NFTs. These aren't just tokens; they're transformative tools that empower you to craft, conjure, and even personalize your online presence. Imagine weaving spells of code to create vibrant websites that pulsate with life, conjuring interactive experiences that captivate your audience, or even enchanting your social media profiles with a touch of digital magic.",
        102: "Dive into a world where retro charm meets boundless potential with PixelLandTitles NFTs. These aren't just quaint digital plots; they're your canvas for pixelated adventures, retro-futuristic creations, and nostalgic communities. Imagine pixelated towns buzzing with vibrant activity, charming 8-bit landscapes teeming with interactive surprises, or even hosting blocky tournaments and pixelated parties in your custom-designed realm.",
        103: "Imagine a pulsating nexus where your NFTs converge, interact, and unlock a world of possibilities. Enter NFTNetNexus, a revolutionary platform that transcends simple storage, transforming your collection into a dynamic network of interconnected assets. Think vibrant constellations where each NFT shines as a star, sparking collaborations, breeding innovative experiences, and even generating new digital creations through their intertwined properties.",
        104: "itness the green revolution of the digital realm with EcoNFTs. These aren't just tokens; they're your stake in a sustainable, conscious metaverse. Imagine owning NFTs that not only grant you unique digital assets but also actively contribute to environmental initiatives in the real world. Think lush virtual forests that filter the data streams of the metaverse, NFTs that power renewable energy sources within the digital landscape, or even owning tokens that fund real-world conservation projects.",
        105: "Witness the transformative power of technology woven with purpose. Blockchain for Impact NFTs aren't just collectibles; they're catalysts for positive change in the real world. Imagine owning an NFT that empowers marginalized communities, funds critical research initiatives, or even grants you voting rights in impactful decentralized organizations. Think NFTs that track and ensure ethical labor practices within the metaverse, tokens that fund educational programs in underprivileged regions, or even owning a piece of a virtual art installation that raises awareness for global issues.",
        106: "Witness the fusion of beauty and beneficence with Artistic Altruism NFTs. These aren't just captivating digital artworks; they're gateways to a world where creativity fuels positive change. Imagine owning an NFT that not only adorns your virtual walls but also empowers underprivileged communities, funds life-saving medical research, or even donates directly to your chosen charity. Think brushstrokes that ripple into real-world donations, canvases pulsating with the joy of giving, and owning art that becomes a symbol of compassion in the digital landscape.",
        107: "n a world of digital abundance, sustainability and fair practices reign supreme with Ethical Editions NFTs. These aren't just collectibles; they're testaments to a responsible and equitable metaverse. Imagine owning an NFT that not only boasts stunning visuals but also guarantees fair compensation for artists, utilizes eco-friendly blockchain technology, and fosters a diverse, inclusive community. Think digital artworks crafted with ethically sourced materials, NFTs audited for transparency and fairness, and owning a piece of the metaverse that champions equality and sustainability.",
        108: "Unleash the power of art to spark change with Artistic Activism NFTs. These aren't just pixels on a screen; they're potent tools for raising awareness, igniting dialogue, and challenging the status quo. Imagine owning an NFT that pulsates with a vital message, provokes critical thinking about pressing issues, and even mobilizes virtual communities to take real-world action. Think animated protests projected across the metaverse, NFTs that fund investigative journalism, and owning art that becomes a catalyst for social change.",
        109: "Beyond mere digital assets, Tech4Good Tokens unlock a world where technology empowers a brighter future. These aren't just tokens; they're seeds of positive change, bridging the gap between the possibilities of the digital and the needs of the real world. Imagine owning tokens that fund vital research initiatives, empower marginalized communities with access to critical technology, or even fuel sustainable development projects across the globe.",
        110: "In a digital landscape teeming with pixels, Heartfelt Holdings NFTs curate a space for emotional connection and meaningful impact. These aren't just artworks; they're vessels for empathy, sparking compassion and igniting a desire to give back. Imagine owning NFTs that tell stories of human resilience, connect you to communities in need, or even empower you to directly support causes close to your heart. Think brushstrokes imbued with emotion, digital canvases that resonate with empathy, and owning art that becomes a bridge between the virtual and the human experience.",
        111: "Embrace a movement where creativity meets collective impact with NFTs for Social Good. These aren't just collectibles; they're catalysts for positive change, fueled by the combined power of art, technology, and human ingenuity. Imagine owning an NFT that not only grants you unique digital assets but also contributes to a global network of social good initiatives, tackles pressing environmental challenges, or even democratizes access to vital resources. Think code written with the purpose of change, pixels dancing with a rhythm of hope, and owning a piece of a movement that redefines the role of NFTs as beacons of positive action."
    }
  }
  
};
import React, { useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import { CONSTANT } from "../../config/Constant";
import HelmetTag from "./HelmetTag";
const Seo: React.FC <any>= (
	{ extra_data = '' } : any
	) => {
		const[metaTitle,setMetaTitle] = useState<String>();
		const[metaDescription,setMetaDescription] = useState<String>();

		const pID: any = extra_data?.ID;
		const META_ARRAY: any = CONSTANT.SEO.META_DETAILS;
		const description_array: any = CONSTANT.SEO.PRODUCT_DESCRIPTION;
		const description = description_array.hasOwnProperty(pID) ? description_array[pID] : extra_data?.description;

		const meta_value = META_ARRAY && META_ARRAY.find((meta: any) => {
			return meta?.ID == pID;
		})
		
		useEffect(()=>{
			if(meta_value && meta_value != 'undefined') {
				console.log('meta_value',meta_value);
				setMetaTitle(meta_value?.Title);
				setMetaDescription(meta_value?.Description);
			} else {
				setMetaTitle("Unique NFT Marketplace for Digital Arts with Inbuilt Wallet");
				setMetaDescription("Explore AI-generated digital arts in unique NFT marketplace. Espro NFT has an inbuilt wallet and a vast collection of NFTs to choose from in various categories.");
			}
		},[meta_value])
		
		const head_script: any = extra_data ? {
			"@context": "https://schema.org/", 
			"@type": "Product", 
			"name": extra_data?.title,
			"image": extra_data?.image_url,
			"description": description,
			"brand": {
				"@type": "Brand",
				"name": "Espro NFT Marketplace"
			},
			"aggregateRating": {
				"@type": "AggregateRating",
				"ratingValue": "4.5",
				"bestRating": "5",
				"worstRating": "1",
				"ratingCount": "60"
			},
			"offers": {
				"@type": "Offer",
				"url": window.location.href,
				"priceCurrency": "ESPC",
				"price": extra_data?.price,
				"availability": "https://schema.org/InStock",
				"itemCondition": "https://schema.org/NewCondition"
			}
		} 
		: 
	'';

	return (
		<HelmetTag 
			extra_data={extra_data} 
			head_script={head_script}
			title={metaTitle} 
			metaDescription={metaDescription} 
		/>
	);
};

export default Seo;

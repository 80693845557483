import React from "react";
import { Footer } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import LOGO_IMAGE from "../../../../assets/images/logo-dark.svg";
import FooterBG from "../../../../assets/images/footer-bg.png";
import moment from "moment";
// import { ReactComponent as FacebookIcon } from "../../../../assets/images/icons/facebook.svg";
// import { ReactComponent as TwitterIcon } from "../../../../assets/images/icons/twitter.svg";
// import { ReactComponent as InstagramIcon } from "../../../../assets/images/icons/instagram.svg";
// import { ReactComponent as YoutubeIcon } from "../../../../assets/images/icons/mingcute_youtube-fill.svg";
// import { ReactComponent as RedditIcon } from "../../../../assets/images/icons/teenyicons_reddit-solid.svg";
// import { ReactComponent as DiscordIcon } from "../../../../assets/images/icons/pajamas_discord.svg";

const FooterView: React.FC = () => {
	return (
		<Footer className="footer bg_img" style={{backgroundImage:`url(${FooterBG})`}}>
			<div className="container">
				<div className="content_wrap">
                    <Link to="/" className="logo">
                        <img src={LOGO_IMAGE} alt="BlockChain" />
                    </Link>
                    <p>A platform that makes it easy for everyone to create, trade, monetize, and market AI-powered NFTs or digital arts. Your Data is Safe with Us and We Respect Your Privacy. Read Our <Link to={'/privacy-policy'} >Privacy Policy</Link> Here.</p>
                    {/* <ul>
                        <li>
                            <Link to="/#" target='blank' className="btn btn_primary_br"><FacebookIcon /></Link>
                        </li>
                        <li>
                            <Link to="/#" target='blank' className="btn btn_primary_br"><TwitterIcon /></Link>
                        </li>
                        <li>
                            <Link to="/#" target='blank' className="btn btn_primary_br"><InstagramIcon /></Link>
                        </li>
                        <li>
                            <Link to="/#" target='blank' className="btn btn_primary_br"><YoutubeIcon /></Link>
                        </li>
                        <li>
                            <Link to="/#" target='blank' className="btn btn_primary_br"><RedditIcon /></Link>
                        </li>
                        <li>
                            <Link to="/#" target='blank' className="btn btn_primary_br"><DiscordIcon /></Link>
                        </li>
                    </ul> */}
                </div>
			</div>
            <div className="cpy_wrap">
                
                <p>© ESPRO NFT Marketplace {moment().year()} -  <Link to={'/terms-of-service'}> Terms of Service</Link> - <Link to={'/privacy-policy'}>Privacy Policy</Link> - <Link to={'/general-risk-warning'}>General Risk Warning</Link></p>
            </div>
		</Footer>
	);
};

export default FooterView;

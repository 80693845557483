import React, { useEffect, useState } from 'react';
import { Table, Tooltip, Modal, Tag } from "antd";
import { dateFormatter, addressFormatter, descendingFormate } from "../../../config/Global";
import { CONSTANT } from "../../../config/Constant";


const AuctionBidList: React.FC<any> = ({
    isModalOpen,
    setOpen,
    lists
}) => {

    const [listing,setListing] = useState<any>([]);

    useEffect(() => {
        const idWiseList = Object.keys(lists)
                .filter(key => lists[key].auctionId ==  isModalOpen.key)
                .map((key) => {
                    return lists[key];
                }, {lists})
        setListing(idWiseList)      
    },[lists]);

    const columns = [
    {
        title: "Bid Id",
        dataIndex: "key",
        key: "key",
    },
    {
        title: "Auction Id",
        dataIndex: "auctionId",
        key: "auctionId",
    },
    {
        title: "Buyer",
        dataIndex: "buyer",
        key: "buyer",
        render: (e: any) => (
            <Tooltip placement="topLeft" title={e}>
                <Tag>{addressFormatter(e)}</Tag>
            </Tooltip>
        ),
    },
    {
        title: "Created Date",
        dataIndex: "createdDate",
        key: "createdDate",
        render: (e: any) => dateFormatter(
            e,
            CONSTANT.DATE_FORMATS.YMDHA
        ),
    },
    ];

    return (
        <>
            {/* <Modal title={`Bid Listing`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000}> */}

            <Modal
				title=""
				centered
				open={isModalOpen}
				onOk={() => setOpen(false)}
				onCancel={() => setOpen(false)}
				maskClosable={false}
				footer={null}
				closeIcon={
					<span className="btn btn_primary btn_modalClose" title="Close">
						<span className="iconFont-close"></span>
					</span>
				}
				width={1100}
			>

            <Table
                className="detailsTab detailsTab_table"
                key={"transaction_list"}
                rowClassName="input_group"
                style={{ color: "#fff", background: "transparent" }}
                columns={columns}
                dataSource={listing ? descendingFormate(listing) : []}
            />
            </Modal>
        </>
    );
};

export default AuctionBidList;
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Button, Row, Col, Upload, message, Form } from "antd";
import type { UploadProps } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { CONSTANT } from "../../../config/Constant";
import useStore from "../../../store";
import { NftRequest } from "../../../requests/NftReuqest";
import { Web3Storage } from "web3.storage";
import { Notification } from "../../../config/Global";
import { useWeb3React } from "@web3-react/core";
import { useWriteContract } from "../../../hooks";
import { useNavigate } from "react-router-dom";
const { Dragger } = Upload;
const storage_token: any = process.env.REACT_APP_WEB3_STORAGE;

const AddProduct: React.FC<any> = observer(() => {
	const { provider, account, chainId } = useWeb3React();
	const { createProductProcess,loading,contractESPROToken } = useWriteContract();
	const [form] = Form.useForm();
	const { NFT_MARKETPLACE_STORE } = useStore();
	const navigate = useNavigate();

	const { file, setFile } = NFT_MARKETPLACE_STORE;

	const totalexitingProductCount: any = localStorage.getItem('product_count') ? localStorage.getItem('product_count') : 0;
	const [saving, setSaving] = useState<any>(loading);
	const [fileList, setFileList] = useState<any>([]);
	const [productLength, setProductLength] = useState<any>(totalexitingProductCount);
	const [storageClient,setStorageClient] = useState<any>([]);
	
	
	
	useMemo(() => {
		const client = new Web3Storage({
			token: storage_token,
		});
		setStorageClient(client)
	},[storage_token]);

	/* Upload Image */
	const storeFiles = useCallback(async (file: any,final_payload: any) => {
		const fileName = file.name.replace(/\s/g, "_");
		const newFile = new File([file], fileName, { type: file.type });
		const img_files = [newFile];
		const img_cid = await storageClient.put(img_files, { name: fileName});
		const imageURI = `https://${img_cid}.ipfs.w3s.link/${fileName}`;
		const next_pid = productLength;		
		const nft_json = {
			name : final_payload.title,
			description : final_payload.description,
			image : imageURI
		};
		const jsonBlob = new Blob([JSON.stringify(nft_json)], { type: 'application/json' });
		const jsonFile = new File([jsonBlob], `${next_pid}.json`);
		const files = [jsonFile];
		const cid = await storageClient.put(files);
		return { "cid" : cid, "img_url" : imageURI}
		
	},[productLength,storageClient]);
	/* Upload Image from URL */
	const handleUploadUrl_img = useCallback(async (url: any,final_payload: any) => {
		try {
			const response = await fetch(url);
			const imageBlob = await response.blob();
			const filename = url.split("/").pop();
			
			const next_pid = productLength;
			
			const newFile = new File([imageBlob], `${filename}`);
			const img_files = [newFile];
			const img_cid = await storageClient.put(img_files);
			const imageURI = `https://${img_cid}.ipfs.w3s.link/${filename}`;
			const nft_json = {
				name : final_payload.title,
				description : final_payload.description,
				image : imageURI
			};
			const jsonBlob = new Blob([JSON.stringify(nft_json)], { type: 'application/json' });
			const jsonFile = new File([jsonBlob], `${next_pid}.json`);
			const files = [jsonFile];
			const cid = await storageClient.put(files);
			return { "cid" : cid, "img_url" : imageURI}
			
		} catch (error) {
			console.error(error);
		}
	},[productLength,storageClient]);

	const props: UploadProps = {
		name: "file",
		multiple: true,
		maxCount: 1,
		accept: ".JPG, .PNG, .WEBM",
		beforeUpload: () => {
			return false;
		},
		onChange(info) {
			handleChange();
			const { status } = info.file;
			if (status !== "uploading") {
				setFile(info.file);
				setFileList(info.fileList);
			}
			if (status === "done") {
				message.success(`${info.file.name} file uploaded successfully.`);
			} else if (status === "error") {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	};

	const handleCreateNft = async (payload: any) => {
		await createProductProcess(payload,account)
			.then(() => {
				form.resetFields();
				setTimeout(() => {
					navigate("/")
				}, 500);
				setSaving(false);
			})
			.catch(() => {
				setSaving(false);
			});
	};
	
	
	const handleChange = () => {
		form
			.validateFields()
			.then(() => {
				// setDisabled(false);
			})
			.catch(() => {
				// setDisabled(true);
			});
	};

	const formSubmitHandler =  async (data: any) => {

		const checkBalance = await contractESPROToken?.balanceOf(account);
		
		if (checkBalance.toString() < 0 ) {
			message.error("Please buy ESPRO TOKEN");
			return;
		}

		if (!provider || !account) {
			message.error("Please connect Wallet");
			return;
		}

		if (chainId && chainId !== CONSTANT.ACTIVE_NETWORK_ID) {
			message.error("Please Select valide Network : "+CONSTANT.ACTIVE_NETWORK_ID);
			return;
		}

		if(data.category == 0) {
			message.error("Please Select Category");
			return;
		}

			setSaving(true);
			
			const final_payload:any = {
				category: data.category,
				description: data.description,
				price: data.price,
				quantity: data.quantity,
				title: data.title
			};
			if (file) {
				await storeFiles(file,final_payload)
					.then(async (res_obj: any) => {
						final_payload.image_url = res_obj.img_url;
						final_payload.c_id = res_obj.cid;
						
						setFile(null);
						setFileList([]);
						//form.resetFields();
						handleCreateNft(final_payload);
					})
					.catch((err: any) => {
						setSaving(false);
						Notification.error({ message: err.toString() });
					});					
			} else {
				await handleUploadUrl_img(data.image_url_h,final_payload)
					.then(async (res_obj: any) => {
						final_payload.image_url = res_obj.img_url;
						final_payload.c_id = res_obj.cid;
						setFileList([]);
						//form.resetFields();
						handleCreateNft(final_payload);
					})
					.catch((err: any) => {
						setSaving(false);
						Notification.error({ message: err.toString() });
					});					
			}
	};

	useEffect(()=>{
		setProductLength(parseInt(totalexitingProductCount)+1)
	},[totalexitingProductCount]);

	return (
		<section className="addProduct pv-80">
			<div className="container_sm">
				<h2>Create New NFT</h2>
				<hr />
				<p>
					You can set preferred display name, create your profile URL and manage
					other personal settings.
				</p>
				<br />
				<FormBox
					name="basic"
					form={form}
					labelCol={{ span: 24 }}
					wrapperCol={{ span: 24 }}
					autoComplete="off"
					onChange={handleChange}
					onFinish={formSubmitHandler}
				>
					<Row className="borderWhite">
						<Col xl={24} md={24} className="input_group">
							<InputBox.Text
								placeholder="Image URL..."
								label="Image URL"
								name="image_url_h"
								onChange={handleChange}
								rules={NftRequest.image_url_h}
							/>
						</Col>
						<Col xl={24} md={24} className="input_group">
							<span className="btn btn_primary btn_none btn_or">OR</span>
						</Col>
						<Col xl={24} md={24} className="input_group input_upload">
							<Form.Item name="image_url_d" rules={NftRequest.image_url_d}>
								<Dragger {...props} fileList={fileList}>
									<p className="ant-upload-text">JPG, PNG, WEBM , MAX 100MB</p>
									<p className="ant-upload-drag-icon">
										<InboxOutlined />
									</p>
									<p className="ant-upload-text">
										Drag &amp; drop file <br /> or Browse media on your device
									</p>
								</Dragger>
							</Form.Item>
						</Col>
					</Row>
					<Row className="borderWhite">
						<Col xl={24} md={24} className="input_group">
							<InputBox.Text
								placeholder="Item Name"
								label="Item Name"
								name="title"
								rules={NftRequest.title}
							/>
						</Col>
						<Col xl={24} md={24} className="input_group">
							<InputBox.TextArea
								placeholder="Description"
								rows={5}
								label="Description"
								name="description"
								rules={NftRequest.description}
							/>
						</Col>
						<Col xl={8} md={12} className="input_group">
							<InputBox.Select
								placeholder="Quantity"
								className="input_select"
								label="Quantity"
								name="quantity"
								rules={NftRequest.quantity}
								options={{
									list: [
										{ id: 1, name: "1" },
										{ id: 2, name: "2" },
										{ id: 3, name: "3" },
										{ id: 4, name: "4" },
										{ id: 5, name: "5" },
										{ id: 6, name: "6" },
										{ id: 7, name: "7" },
										{ id: 8, name: "8" },
										{ id: 9, name: "9" },
										{ id: 10, name: "10" },
										{ id: 11, name: "11" },
										{ id: 12, name: "12" },
									],
									valueKey: "id",
									textKey: "name",
								}}
							/>
						</Col>
						<Col xl={8} md={12} className="input_group">
							<InputBox.Text
								placeholder={CONSTANT.CURRENCY_CODE +" 00.00"}
								label="Price"
								name="price"
								rules={NftRequest.price}
							/>
						</Col>
						<Col xl={8} md={12} className="input_group">
							<InputBox.Select
								placeholder="Select Category"
								className="input_select"
								label="Category"
								name="category"
								rules={NftRequest.category}
								options={{
									list: CONSTANT.NFT_CATEGORY_ARRAY.map(
										({ key, label }) => ({
										id: key,
										name: label,
									})
									),
									valueKey: "id",
									textKey: "name",
								}}
							/>
						</Col>

						<Col xl={24} md={24} className="input_group mt-15">
							<Button
								type="primary"
								loading={saving}
								htmlType="submit"
								disabled={saving}
							>
								Submit
							</Button>
						</Col>
					</Row>
				</FormBox>
			</div>
		</section>
	);
});

export default AddProduct;

import React from "react";
import LOGO_IMAGE from "../../../assets/images/logo-dark.svg";
import { Link } from "react-router-dom";

const LogoComponent: React.FC = () => {
	return (
		<Link to={'/'}>
			<img src={LOGO_IMAGE} alt="BlockChain" />
		</Link>
	);
};

export default LogoComponent;

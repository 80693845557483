import React from "react";
import { Layout } from "antd";
import { Outlet} from "react-router-dom";
import { observer } from "mobx-react";
import HeaderView from "./Header/HeaderView";
import FooterView from "./Footer/FooterView";

const AuthLayout: React.FC = observer(() => {

	return (
		<Layout className={`main__page__wrapper has__header`}>
			<HeaderView />
			<Layout.Content className="main__page__content">
				<Outlet />
			</Layout.Content>
			<FooterView />
		</Layout>
	);
});

export default AuthLayout;

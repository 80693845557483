import React from "react";
import HeaderLogoComponent from "../../Components/LogoComponent";
import HeaderMenuComponent from "../../Components/HeaderMenuComponent";
import { Header } from "antd/lib/layout/layout";
import { Col, Row } from "antd";

const HeaderView: React.FC = () => {
	return (
		<Header className="main__page__header">
			<div className="container">
				<Row>
					<Col xs={{ span: 9 }} md={{ span: 7 }} lg={{ span: 5 }}  className="logo__wrapper"><HeaderLogoComponent /></Col>
					<Col xs={{ span: 15 }} md={{ span: 17 }} lg={{ span: 19 }} className="nav__wrapper" ><HeaderMenuComponent /></Col>
				</Row>
			</div>
		</Header>
	);
};

export default HeaderView;

import { useCallback, useState, useMemo } from "react";

import { message } from "antd";
import { Contract, ethers } from "ethers";

import { useSignerOrProvider } from "./useSignerOrProvider";
import productDefinition from "../contracts/NFTProductContract.json";
import orderDefinition from "../contracts/NFTOrderContract.json";
import auctionDefinition from "../contracts/NFTAuctionContract.json";
import EsproToken from "../contracts/EsproToken.json";
import EsproWallat from "../contracts/EsproWallat.json";
import { CONSTANT } from "../config/Constant";

export const useWriteContract = () => {
  const { signer } = useSignerOrProvider();
  
  const [contractProduct, setContractProduct] = useState<any>([]);
  const [contractOrder, setContractOrder] = useState<any>([]);
  const [contractAuction, setContractAuction] = useState<any>([]);
  const [contractESPROToken, setContractESPROToken] = useState<any>([]);
  const [contractESPROWallat, setContractESPROWallat] = useState<any>([]);
  const [auctionBidsList, setAuctionBidsList] = useState<any>([]);
  

  useMemo(() => {
    const contractProductInit: any = new Contract(CONSTANT.PRODUCT_CONTRACT_ADDRESS,productDefinition.abi,signer);
    const contractOrderInit: any = new Contract(CONSTANT.ORDER_CONTRACT_ADDRESS,orderDefinition.abi,signer);
    const contractAuctionInit: any = new Contract(CONSTANT.AUCTION_CONTRACT_ADDRESS,auctionDefinition.abi,signer);
    const contractTokenInit: any = new Contract(CONSTANT.ESPRO_CONTRACT_ADDRESS,EsproToken.abi,signer);
    const contractTokenWallatInit: any = new Contract(CONSTANT.ESPRO_WALLAT_CONTRACT_ADDRESS,EsproWallat.abi,signer);
    setContractProduct(contractProductInit);
    setContractOrder(contractOrderInit);
    setContractAuction(contractAuctionInit);
    setContractESPROToken(contractTokenInit);
    setContractESPROWallat(contractTokenWallatInit);
  },[signer]);


  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [modelBox, setModelBox] = useState<boolean>(false);
  const [mintResponse, setMintResponse] = useState<any>([]);  

  // transfer welCome COIN
  const transferWelcomeCoin = useCallback(
    async(receiver:any): Promise<void> => {
      try {
        if (!ethers.utils.isAddress(receiver) || CONSTANT.TOKEN_CONTRACT_OWNER_ADDRESS === receiver) {
          throw new Error("Invalid address");
        }
        setLoading(true);
        const checkBalance = await contractESPROToken?.balanceOf(receiver);
        if(checkBalance < 0)
        {
          message.loading('Transfering bonus ESPRO ..',12);
          console.log('checkBalance',checkBalance.toString());
          const tokenWallatAction = await contractESPROWallat?.transferTokensWhenConnectWallet(CONSTANT.TOKEN_CONTRACT_OWNER_ADDRESS,receiver);
          await tokenWallatAction?.wait().then(( res: any) => {
            message.success(`Coin Transferred Successfully!`);
            localStorage.setItem("transfered",receiver);
            setLoading(false);
            // setTimeout(() => {
            //   window.location.reload();
            // }, 500);
          });
        }
      } catch (error: any) {
        const err = error.reason ?? error.message ?? error;
        console.log('err',err);
        setLoading(false);
        return error;
      }

  },[contractESPROWallat,contractESPROToken]);

  // Create New NFT PRODUCT
  const createProductProcess = useCallback(
    async (payload:any, receiver: any): Promise<void> => {
      setLoading(true);
      const itemPrice = payload.price;

        if (!ethers.utils.isAddress(receiver)) {
          throw new Error("Invalid address");
        }
        if (!itemPrice || itemPrice <= 0) {
          throw new Error("Invalid amount");
        }
        //const amountToString = itemPrice.toString();
        const productAction = await contractProduct?.addProduct(
          payload.quantity.toString(),
          payload.category,
          payload.price,
          payload.image_url,
          payload.c_id,
          payload.title,
          // payload.description, {
          //   value: amountToString
          // }
        );          
        await productAction?.wait().then(( res: any) => {
					message.success(`Item created Successfully!`);
					setLoading(false);
				});    
    },
    [contractProduct]
  );
  
  // Mint NFT
  const mintProcess = useCallback(
    async (productInfo:any, receiver: any, Qty: number): Promise<void> => {
      setLoading(true);
      
      try {
        if (!ethers.utils.isAddress(receiver)) {
          setLoading(false);
          throw new Error("Invalid address");
        }
        if (!Qty || Qty <= 0) {
          setLoading(false);
          throw new Error("Invalid Qty");
        }
        const itemPrice = productInfo.price * Qty;

        if (!itemPrice || itemPrice <= 0) {
          setLoading(false);
          throw new Error("Invalid amount");
        }
        //const amountToString = itemPrice.toString();

        //const encodedABI = await contractOrder.mintToken(productInfo.ID,Qty,{ value: amountToString });
        const encodedABI = await contractOrder.mintToken(productInfo.ID,Qty);
        return await encodedABI?.wait().then( async( res: any) => {
					message.success( `Success!`);
					setLoading(false);
          setModelBox(true);
          const address_wise_order = await contractOrder.getAddressWiseOrders(receiver);
          setMintResponse(address_wise_order);
          return res;
				});
      } catch (error: any) {
        const err = error.reason ?? error.message ?? error;
        message.error(`${err}`);
        setModelBox(false);
        return error;
      } finally {
        setLoading(false);
      }
    },
    [contractOrder]
  );

  // update Order Status
  const updateOrderStatus = useCallback(
    async (orders:any, receiver: any, statusCode: any): Promise<void> => {
      setLoading(true);
     
      try {
        if (!ethers.utils.isAddress(receiver)) {
          setLoading(false);
          throw new Error("Invalid address");
        }
        if (!orders) {
          setLoading(false);
          throw new Error("Invalid orders");
        }
        const totalCount = orders ? orders.length : 1;
        orders.map( async(order:any,index:any) => {
          const encodedABI = await contractOrder.updateOrderStatus(order.orderId,statusCode);
          return await encodedABI?.wait().then( async( res: any) => { 
            if ( index+1 === totalCount ) {
              message.success(`Status updated Successfully!`);
              setModelBox(false);
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          });
        });
      } catch (error: any) {
        const err = error.reason ?? error.message ?? error;
        message.error(`${err}`);
        setModelBox(false);
        return error;
      } finally {
        setLoading(false);
      }
    },
    [contractOrder]
  );

  // create Auction
  const createAuctions = useCallback(
    async (orders:any, receiver: any, submitedData: any): Promise<void> => {
      setLoading(true);
     
      try {
        if (!ethers.utils.isAddress(receiver)) {
          setLoading(false);
          throw new Error("Invalid address");
        }
        if (!orders) {
          setLoading(false);
          throw new Error("Invalid orders");
        }

        if (!submitedData) {
          setLoading(false);
          throw new Error("Invalid option selected");
        }
        
        const auctionMinPrice = submitedData ? submitedData?.min_bid_amount : 0;
        const auctionMaxPrice = submitedData ? submitedData?.max_bid_amount : 0;

        if (auctionMinPrice === auctionMaxPrice) {
          setLoading(false);
          throw new Error("Max price should be greater than the minimum price");
        }

        const auctionStartDate = submitedData ? submitedData?.start_date_time : 0;
        const auctionEndDate = submitedData ? submitedData?.end_date_time : 0;
        if (auctionStartDate === auctionEndDate) {
          setLoading(false);
          throw new Error("Invalid auction date Range");
        }
        const auctionStatus = 1;
        const totalCount = orders ? orders.length : 1;
        await Promise.all(
          orders.map( async(order:any,index:any) => {
            const encodedABI = await contractAuction._createAuction(order.orderId,order.tokenId,auctionMinPrice,auctionMaxPrice,auctionStatus,auctionStartDate,auctionEndDate);
            return await encodedABI?.wait(2).then( async( res: any) => { 
              if ( index+1 === totalCount ) {
                message.success(`Auction created Successfully!`);
                setModelBox(false);
                setLoading(false);
                setTimeout(() => {
                	window.location.reload();
                }, 500);
                return res;
              }
            });
          })
        );
      } catch (error: any) {
        const err = error.reason ?? error.message ?? error;
        message.error(`${err}`);
        setModelBox(false);
        return error;
      } finally {
        setLoading(false);
      }
    },
    [contractAuction]
  );
  
  // Place Bid to active Auction
  const placeAuctionBid = useCallback(
    async (bidPrice: any,auctionId: any): Promise<void> => {
      setLoading(true);
      setProcessing(true);
      try {
        const amountToString = bidPrice.toString();
        const placeBidABI = await contractAuction._placeBid(amountToString,auctionId);
        return await placeBidABI?.wait().then( ( res: any) => { 
            message.success(`Bid placed Successfully!`);
            setModelBox(false);
            setTimeout(() => {
              window.location.reload();
            }, 500);
            return res;
        });
      } catch (error: any) {
        const err = error.reason ?? error.message ?? error;
        message.error(`${err}`);
        setLoading(false);
        return error;
      } finally {
        setLoading(false);
        setProcessing(false);
      }
    },
    [contractAuction]
  );

  const listAuctionBids = useCallback(
    async (auctionId:any): Promise<void> => {
        const auctionBidList = await contractAuction.getAuctionBids(auctionId);
        await auctionBidList?.then( async( auctionBidsResponse: any) => { 
          setAuctionBidsList(auctionBidsResponse);
        });

    },[contractAuction]);

  // Token Transfer
  const tokenTransferProcess = useCallback(
    async (id:any, price: any, transferPrice: number): Promise<void> => {
      setLoading(true);
      const hide = message.loading('Action in progress..');
      try {
        if (!price || price <= 0) {
          setLoading(false);
          throw new Error("Invalid Qty");
        }
        if (!transferPrice || transferPrice <= 0) {
          setLoading(false);
          throw new Error("Invalid amount");
        }
        //const amountToString = transferPrice.toString();

        //const encodedABI = await contractOrder.transferToken(id,{ value: amountToString });
        const encodedABI = await contractOrder.transferToken(id);
        return await encodedABI?.wait().then(( res: any) => {
					message.success( `Token Transfer successfully.`);
          setTimeout(() => {
            window.location.reload();
          }, 500);
				});
      } catch (error: any) {
        const err = error.reason ?? error.message ?? error;
        message.error(`${err}`);
        setLoading(false);
      } finally {
        setLoading(false);
        setTimeout(hide, 40000);
      }
    },
    [contractOrder]
  );

  return {
    contractESPROToken,
    loading,
    processing,
    modelBox,
    auctionBidsList,
    mintResponse,
    createProductProcess,
    mintProcess,
    tokenTransferProcess,
    updateOrderStatus,
    createAuctions,
    placeAuctionBid,
    listAuctionBids,
    transferWelcomeCoin
  };
};
